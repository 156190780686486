import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  SET_STUDENT_START,
  SET_STUDENT_SUCCESS,
  SET_STUDENT_FAILURE,
} from './constants'
import { GET_CHECKOUT_SNIPPET_SUCCESS } from '../KlarnaCheckout/constants'
import { LOGIN_SUCCESS } from 'containers/Login/constants'
import { CUSTOMER_TYPES } from 'containers/Pages/Checkout/constants'

const initialState = Immutable({
  error: null,
  loading: false,
  checked: false,
  previous_checked: false,
})

const Reducer = createReducer(initialState, {
  [SET_STUDENT_START](state, { checked }) {
    return state.set('loading', true).set('checked', checked)
  },
  [SET_STUDENT_SUCCESS](state, { checked }) {
    return state
      .set('error', null)
      .set('loading', false)
      .set('previous_checked', checked)
  },
  [SET_STUDENT_FAILURE](state, { error }) {
    return state
      .set('error', error)
      .set('loading', false)
      .set('checked', state.previous_checked)
  },
  [GET_CHECKOUT_SNIPPET_SUCCESS](state, { cart }) {
    return state
      .set('checked', cart.student)
      .set('previous_checked', cart.student)
  },
  [LOGIN_SUCCESS](state, { user }) {
    return state.set('checked', user.customer_type === CUSTOMER_TYPES.STUDENT)
  },
})

export default Reducer
