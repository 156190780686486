export default class TagClasses {
  constructor(classesOrClass) {
    if (typeof classesOrClass === 'undefined') {
      this.classes = []
    } else {
      this.classes = Array.isArray(classesOrClass)
        ? classesOrClass
        : [classesOrClass]
    }
  }

  add(className) {
    this.classes = this.classes.concat(className)
  }

  remove(className) {
    this.classes = this.classes.filter(
      classesClassName => classesClassName !== className
    )
  }

  toString() {
    return this.classes.join(' ')
  }
}
