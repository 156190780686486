import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { request } from 'utils/request'

import endpoints from 'api/endpoints'
import {
  getGiftWrapFailure,
  getGiftWrapSuccess,
  saveGiftWrapFailure,
  saveGiftWrapSuccess,
} from './actions'
import { GET_GIFT_WRAP_START, SAVE_GIFT_WRAP_START } from './constants'
import { hideModal } from 'containers/GUI/actions'
import { getCartStart } from '../Cart/actions'

export function* sagaGetGiftWrapStart() {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createGiftWrapUrl(state.session.sessionId),
      'GET'
    )
    yield put(getGiftWrapSuccess(response))
  } catch (e) {
    yield put(getGiftWrapFailure(e))
  }
}

export function* sagaSaveGiftWrapStart({ productsToGiftWrap }) {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createGiftWrapUrl(state.session.sessionId),
      'POST',
      productsToGiftWrap
    )
    yield put(hideModal())
    yield put(saveGiftWrapSuccess(response))
    yield put(getCartStart(state.session.sessionId))
  } catch (e) {
    yield put(saveGiftWrapFailure(e))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_GIFT_WRAP_START, sagaGetGiftWrapStart),
    takeLatest(SAVE_GIFT_WRAP_START, sagaSaveGiftWrapStart),
  ])
}
