import {
  GIFT_CERTIFICATE_TOGGLE,
  UPDATE_GIFT_CERTIFICATE,
  ADD_GIFT_CERTIFICATE_START,
  ADD_GIFT_CERTIFICATE_FAILURE,
  ADD_GIFT_CERTIFICATE_SUCCESS,
  GIFT_CERTIFICATE_CLEAR_ERROR,
  GIFT_CERTIFICATE_SET_VALIDATED,
  DELETE_GIFT_CERTIFICATE_START,
  DELETE_GIFT_CERTIFICATE_SUCCESS,
  DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
} from './constants'

export const giftCertificateToggle = () => ({
  type: GIFT_CERTIFICATE_TOGGLE,
})

export const updateGiftCertificate = giftCertificate => ({
  type: UPDATE_GIFT_CERTIFICATE,
  giftCertificate,
})

export const addGiftCertificateStart = giftCertificate => ({
  type: ADD_GIFT_CERTIFICATE_START,
  giftCertificate,
})

export const addGiftCertificateSuccess = cart => ({
  type: ADD_GIFT_CERTIFICATE_SUCCESS,
  cart,
})

export const addGiftCertificateFailure = error => ({
  type: ADD_GIFT_CERTIFICATE_FAILURE,
  error,
})

export const giftCertificateClearError = () => ({
  type: GIFT_CERTIFICATE_CLEAR_ERROR,
})

export const giftCertificateSetValidated = validated => ({
  type: GIFT_CERTIFICATE_SET_VALIDATED,
  validated,
})

export const deleteGiftCertificateStart = giftCertificateId => ({
  type: DELETE_GIFT_CERTIFICATE_START,
  giftCertificateId,
})

export const deleteGiftCertificateSuccess = (giftCertificateId, cart) => ({
  type: DELETE_GIFT_CERTIFICATE_SUCCESS,
  giftCertificateId,
  cart,
})

export const deleteGiftCertificateFailure = (giftCertificateId, error) => ({
  type: DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
  giftCertificateId,
  error,
})
