import {
  GET_CHECKOUT_SNIPPET_START,
  GET_CHECKOUT_SNIPPET_SUCCESS,
  GET_CHECKOUT_SNIPPET_FAILURE,
  KCO_SCRIPT_EXECUTION_ERROR,
  KCO_SUSPEND,
  KCO_RESUME,
  ADD_SUSPEND_ACTION,
  DELETE_SUSPEND_ACTION,
  SHIPPING_ADDRESS_CHANGE_START,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_START,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE,
  UPDATE_SHIPPING_OPTION_SELECTED,
} from './constants'

export const getCheckoutSnippetStart = () => ({
  type: GET_CHECKOUT_SNIPPET_START,
})

export const getCheckoutSnippetSuccess = cart => {
  return {
    type: GET_CHECKOUT_SNIPPET_SUCCESS,
    cart,
  }
}

export const getCheckoutSnippetFailure = error => ({
  type: GET_CHECKOUT_SNIPPET_FAILURE,
  error,
})

export const kcoScriptExecutionError = error => ({
  type: KCO_SCRIPT_EXECUTION_ERROR,
  error,
})

export const shippingAddressChangeStart = data => ({
  type: SHIPPING_ADDRESS_CHANGE_START,
  data,
})

export const setDeliveryAddressCountryIsoStart = countryIso => ({
  type: SET_DELIVERY_ADDRESS_COUNTRY_ISO_START,
  countryIso,
})

export const setDeliveryAddressCountryIsoSuccess = cart => ({
  type: SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS,
  cart,
})

export const setDeliveryAddressCountryIsoFailure = error => ({
  type: SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE,
  error,
})

export const kcoSuspend = () => ({
  type: KCO_SUSPEND,
})

export const kcoResume = () => ({
  type: KCO_RESUME,
})

export const addSuspendAction = baseActionType => ({
  type: ADD_SUSPEND_ACTION,
  baseActionType,
})

export const deleteSuspendAction = baseActionType => ({
  type: DELETE_SUSPEND_ACTION,
  baseActionType,
})

export const updateShippingOptionSelected = option => ({
  type: UPDATE_SHIPPING_OPTION_SELECTED,
  option,
})
