import React, { Fragment } from 'react'

import { FORGOT_PASSWORD_PAGE_PATH } from 'components/App/constants'

import { VALIDATION_MESSAGES, translate } from './language'

export function validationMessage(code) {
  let messages = []
  let message = ''

  if (VALIDATION_MESSAGES.hasOwnProperty(code)) {
    message = VALIDATION_MESSAGES[code]
  } else {
    message = translate('DEFAULT_ERROR')
  }

  messages = messages.concat({
    code: code,
    message: message,
  })

  if (code === 403) {
    messages = messages.concat({
      code: `${code}_2`,
      message: (
        <Fragment>
          {` `}
          <a href={FORGOT_PASSWORD_PAGE_PATH}>
            {translate('FORGOT_USERNAME_OR_PASSWORD_LONG')}
          </a>
        </Fragment>
      ),
    })
  }

  return (
    <Fragment>
      {messages.map(message => (
        <Fragment key={message.code}>{message.message}</Fragment>
      ))}
    </Fragment>
  )
}
