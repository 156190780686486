import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import TagClasses from 'utils/TagClasses'
import InfoIcon from 'components/Icons/Info'
import ReportProblemIcon from 'components/Icons/ReportProblem'
import ClearIcon from 'components/Icons/Clear'
import InfoInverseIcon from 'components/Icons/InfoInverse'

export default class AlertMessage extends PureComponent {
  static propTypes = {
    variant: PropTypes.oneOf([
      'success',
      'info',
      'warning',
      'danger',
      'primary',
    ]).isRequired,
    children: PropTypes.node.isRequired,
    closeButton: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      show: true,
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.setState({ show: false })
  }

  getIcon() {
    const icons = {
      success: <InfoIcon type="dark" />,
      info: <InfoIcon type="info" />,
      warning: <ReportProblemIcon color="#F2BF0A" />,
      danger: <ReportProblemIcon />,
      primary: <InfoIcon type="dark" />,
      infoInverseIcon: <InfoInverseIcon type="light" />,
    }

    return icons[this.props.icon]
      ? icons[this.props.icon]
      : icons[this.props.variant]
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.children !== prevProps.children) {
      this.setState({ show: true })
    }
  }

  render() {
    const { show } = this.state
    const { children, variant, closeButton = true } = this.props
    let classes = new TagClasses(['alert-has-close'])

    if (this.props.className) {
      classes.add(this.props.className)
    }

    if (show) {
      return (
        <Alert className={classes.toString()} variant={variant}>
          <div className="alert__content alert__content-with-icon">
            {this.getIcon()}
            <span className="alert__content__text">{children}</span>
          </div>
          {closeButton && (
            <Button
              className="alert__close"
              variant="link"
              type="button"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <ClearIcon color="#555555" width={20} height={20} />
            </Button>
          )}
        </Alert>
      )
    }

    return null
  }
}
