import { all, takeLatest, put, select } from 'redux-saga/effects'

import { getParameter } from 'utils/common'
import { ADDRESS_MODAL, CHANGES_MODAL } from 'containers/GUI/constants'
import { GET_CHECKOUT_SNIPPET_SUCCESS } from 'containers/Pages/Checkout/KlarnaCheckout/constants'
import { showModal } from 'containers/GUI/actions'

import { validationModalShown } from './actions'

function* sagaValidationModal(action) {
  const state = yield select()

  if (
    !state.checkoutPage.validationModalShown &&
    !action.cart.validation_messages.valid
  ) {
    const post = getParameter('post')

    if (post === 'true') {
      if (action.cart.validation_messages.customer.length) {
        yield put(showModal(ADDRESS_MODAL))
      } else {
        yield put(showModal(CHANGES_MODAL))
      }

      yield put(validationModalShown())
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(GET_CHECKOUT_SNIPPET_SUCCESS, sagaValidationModal)])
}
