import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'
import Cookies from 'js-cookie'

import {
  GET_CART_START,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  REFRESH_SHIPPING_START,
  REFRESH_SHIPPING_SUCCESS,
  REFRESH_SHIPPING_FAILURE,
  SHOW_REMOVE_CART_ITEM_DIALOG,
  HIDE_REMOVE_CART_ITEM_DIALOG,
  UPDATE_CART_ITEM_START,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_CART_ITEM_FAILURE,
} from './constants'
import {
  ADD_GIFT_CERTIFICATE_SUCCESS,
  DELETE_GIFT_CERTIFICATE_SUCCESS,
} from './GiftCertificate/constants'
import {
  ADD_CAMPAIGN_CODE_SUCCESS,
  DELETE_CAMPAIGN_CODE_SUCCESS,
} from './CampaignCode/constants'
import { SET_NEWSLETTER_SUCCESS } from '../Newsletter/constants'
import {
  GET_CHECKOUT_SNIPPET_SUCCESS,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS,
} from '../KlarnaCheckout/constants'
import { GET_RESET_ORDER_SUCCESS } from '../ResetOrder/constants'
import { GET_CONFIRMATION_CART_SUCCESS } from 'containers/Pages/Checkout/ConfirmationPage/ConfirmationCart/constants'
import {
  CART_COOKIE_NAME,
  CART_COOKIE_DOMAIN,
  deliveryTimesMessageRange,
} from 'utils/config'
import { calculateMaxDifference } from 'utils/common'
import { ITEM_STATUS } from 'containers/GoogleTagManager/dataLayer/utils'

const cookieName = CART_COOKIE_NAME
const cookieDomain =
  window.location.hostname === 'localhost' ? 'localhost' : CART_COOKIE_DOMAIN

const cookies = Cookies.withConverter({
  write: function(value, name) {
    return value
  },
})

const initialState = Immutable({
  ui: {
    error: null,
    loading: false,
    displayDeliveryTimesMessage: false,
    shippingLoading: false,
    cartItems: [],
    allItemsInStock: false,
    anyPreorderItems: false,
  },
  data: {},
})

const updateBasketCookie = products => {
  let productIdAndQuantity = []
  products.forEach(product => {
    productIdAndQuantity.push(product.product_id + ':' + product.quantity)
  })
  const cookieValue = productIdAndQuantity.join(',')
  setBasketCookie(cookieValue)
}

const getCookieExpireDate = () => {
  var oneYearFromNow = new Date()
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
  return oneYearFromNow
}

const eraseBasketCookie = () => {
  setBasketCookie('')
}

const setBasketCookie = cookieValue => {
  const expires = getCookieExpireDate()
  cookies.set(cookieName, cookieValue, {
    domain: cookieDomain,
    expires: expires,
    path: '/',
  })
}

const updateCart = (cart, state, erase) => {
  const cartItems = new Array(cart.products.length).fill({
    showRemoveDialog: false,
    loading: false,
  })

  if (erase) {
    eraseBasketCookie()
  } else {
    updateBasketCookie(cart.products)
  }

  const displayDeliveryTimesMessage = calculateDisplayDeliveryTimesMessage(
    cart.products,
    cart.xmas_delivery
  )

  const allItemsInStock = calculateAllItemsInStock(cart.products)
  const anyPreorderItems = findAnyPreorderItems(cart.products)

  const cartState = Immutable({
    ui: {
      cartItems: cartItems,
      loading: state.ui.loading,
      allItemsInStock,
      anyPreorderItems,
      displayDeliveryTimesMessage,
      shippingLoading: state.ui.shippingLoading,
      error: state.ui.error,
    },
    data: cart,
  })

  return cartState
}

const updateCartRefreshShipping = (cart, state) => {
  const cartItems = new Array(cart.products.length).fill({
    showRemoveDialog: false,
    loading: false,
  })

  // Since the call to refresh-shipping resets validation messages
  // and the klarna html snippet we save them
  cart.html_snippet = state.data.html_snippet
  cart.validation_messages = state.data.validation_messages

  const displayDeliveryTimesMessage = calculateDisplayDeliveryTimesMessage(
    cart.products,
    cart.xmas_delivery
  )

  const allItemsInStock = calculateAllItemsInStock(cart.products)
  const anyPreorderItems = findAnyPreorderItems(cart.products)

  const cartState = Immutable({
    ui: {
      cartItems: cartItems,
      loading: state.ui.loading,
      allItemsInStock,
      anyPreorderItems,
      displayDeliveryTimesMessage,
      shippingLoading: state.ui.shippingLoading,
      error: state.ui.error,
    },
    data: cart,
  })

  return cartState
}

const updateCartItemLoading = (index, loading, prevCartItems) => {
  const cartItems = prevCartItems.map((prevItem, prevItemIndex) => {
    return {
      showRemoveDialog: prevItem.showRemoveDialog,
      loading: prevItemIndex === index ? loading : prevItem.loading,
    }
  })

  return cartItems
}

const getProductIndexFromId = (productId, products) => {
  return products.findIndex(product => product.product_id === productId)
}

const setShowRemoveCartItemByIndex = (cartItems, productIndex, show) => {
  return cartItems.map((item, index) => {
    return productIndex === index
      ? { ...item, showRemoveDialog: show }
      : { ...item }
  })
}

const calculateDisplayDeliveryTimesMessage = (products, xmasDelivery) => {
  const maxProcessingTimes = products.map(product => {
    return product.max_processing_time
  })
  const maxDiff = calculateMaxDifference(maxProcessingTimes)
  let result = deliveryTimesMessageRange <= maxDiff

  // Never show delivery times messages when xmas is active.
  result = result && (xmasDelivery === undefined || xmasDelivery.status === 0)

  return result
}

const calculateAllItemsInStock = products => {
  return products.every(
    product => product.hasOwnProperty('in_stock') && product.in_stock === true
  )
}

const findAnyPreorderItems = products => {
  return products.some(product => product.extra.status === ITEM_STATUS.PREORDER)
}

const Reducer = createReducer(initialState, {
  [GET_CART_START](state) {
    return state.setIn(['ui', 'loading'], true).setIn(['ui', 'error'], null)
  },
  [GET_CART_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
      .setIn(['ui', 'loading'], false)
      .setIn(['ui', 'error'], null)
  },
  [GET_CART_FAILURE](state, { error }) {
    return state.setIn(['ui', 'loading'], false).setIn(['ui', 'error'], error)
  },
  [REFRESH_SHIPPING_START](state) {
    return state
      .setIn(['ui', 'shippingLoading'], true)
      .setIn(['ui', 'error'], null)
  },
  [REFRESH_SHIPPING_SUCCESS](state, { cart }) {
    return updateCartRefreshShipping(cart, state)
      .setIn(['ui', 'shippingLoading'], false)
      .setIn(['ui', 'error'], null)
  },
  [REFRESH_SHIPPING_FAILURE](state, { error }) {
    return state
      .setIn(['ui', 'shippingLoading'], false)
      .setIn(['ui', 'error'], error)
  },
  [SHOW_REMOVE_CART_ITEM_DIALOG](state, { productId }) {
    const productIndex = getProductIndexFromId(productId, state.data.products)
    const cartItems = setShowRemoveCartItemByIndex(
      state.ui.cartItems,
      productIndex,
      true
    )
    return state.setIn(['ui', 'cartItems'], cartItems)
  },
  [HIDE_REMOVE_CART_ITEM_DIALOG](state, { productId }) {
    const productIndex = getProductIndexFromId(productId, state.data.products)
    const cartItems = setShowRemoveCartItemByIndex(
      state.ui.cartItems,
      productIndex,
      false
    )
    return state.setIn(['ui', 'cartItems'], cartItems)
  },
  [UPDATE_CART_ITEM_START](state, { productId }) {
    const index = getProductIndexFromId(productId, state.data.products)
    const cartItems = updateCartItemLoading(index, true, state.ui.cartItems)
    return state
      .setIn(['ui', 'error'], null)
      .setIn(['ui', 'cartItems'], cartItems)
  },
  [UPDATE_CART_ITEM_SUCCESS](state, { productId, cart }) {
    const newState = updateCart(cart, state)
    return newState.setIn(['ui', 'error'], null)
  },
  [UPDATE_CART_ITEM_FAILURE](state, { productId, error }) {
    const index = getProductIndexFromId(productId, state.data.products)
    const cartItems = updateCartItemLoading(index, false, state.ui.cartItems)
    return state
      .setIn(['ui', 'error'], error)
      .setIn(['ui', 'cartItems'], cartItems)
  },
  [ADD_GIFT_CERTIFICATE_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [ADD_CAMPAIGN_CODE_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [DELETE_CAMPAIGN_CODE_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [DELETE_GIFT_CERTIFICATE_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [GET_CHECKOUT_SNIPPET_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [GET_RESET_ORDER_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS](state, { cart }) {
    return updateCart(cart, state)
  },
  [GET_CONFIRMATION_CART_SUCCESS](state, { cart }) {
    return updateCart(cart, state, true)
  },
  [SET_NEWSLETTER_SUCCESS](state, { checked }) {
    return state.setIn(['data', 'newsletter'], checked)
  },
})

export default Reducer
