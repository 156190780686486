import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  ADD_PERSONAL_NUMBER_START,
  ADD_PERSONAL_NUMBER_SUCCESS,
  ADD_PERSONAL_NUMBER_FAILURE,
  UPDATE_PERSONAL_NUMBER,
  SHOW_PERSONAL_NUMBER_FORM,
} from './constants'

const initialState = Immutable({
  personalNumber: '',
  error: null,
  loading: false,
  showForm: false,
})

const Reducer = createReducer(initialState, {
  [SHOW_PERSONAL_NUMBER_FORM](state, { show }) {
    return state.set('showForm', show)
  },
  [UPDATE_PERSONAL_NUMBER](state, { personalNumber }) {
    return state.set('personalNumber', personalNumber)
  },
  [ADD_PERSONAL_NUMBER_START](state) {
    return state.set('error', null).set('loading', true)
  },
  [ADD_PERSONAL_NUMBER_SUCCESS](state) {
    return state
      .set('error', null)
      .set('loading', false)
      .set('personalNumber', '')
      .set('showForm', false)
  },
  [ADD_PERSONAL_NUMBER_FAILURE](state, { error }) {
    return state.set('error', error).set('loading', false)
  },
})

export default Reducer
