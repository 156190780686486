import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  GET_RESET_ORDER_START,
  GET_RESET_ORDER_SUCCESS,
  GET_RESET_ORDER_FAILURE,
} from './constants'

const initialState = Immutable({
  loading: false,
  error: null,
})

const Reducer = createReducer(initialState, {
  [GET_RESET_ORDER_START](state) {
    return state.set('loading', true).set('error', null)
  },
  [GET_RESET_ORDER_SUCCESS](state) {
    return state.set('loading', false).set('error', null)
  },
  [GET_RESET_ORDER_FAILURE](state, { error }) {
    return state.set('loading', false).set('error', error)
  },
})

export default Reducer
