import {
  SHOW_MENU,
  HIDE_MENU_WITH_ANIMATION,
  HIDE_MENU_NO_ANIMATION,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_POPOVER,
  HIDE_POPOVER,
} from './constants'

export const showMenuWithAnimation = () => ({
  type: SHOW_MENU,
})

export const hideMenuWithAnimation = () => ({
  type: HIDE_MENU_WITH_ANIMATION,
})

export const hideMenuNoAnimation = () => ({
  type: HIDE_MENU_NO_ANIMATION,
})

export const showModal = modal => ({
  type: SHOW_MODAL,
  modal,
})

export const hideModal = () => ({
  type: HIDE_MODAL,
})

export const showPopover = popover => ({
  type: SHOW_POPOVER,
  popover,
})

export const hidePopover = () => ({
  type: HIDE_POPOVER,
})
