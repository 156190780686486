import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'
import rootSaga from './sagas'
import reducer from './reducers'

import getEnvConfig from 'utils/getEnvConfig'

// Use redux devtools except in production.
const dev = getEnvConfig('MODE') !== 'prod'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  dev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

let store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

export default store
