import { calculateDiscount, convertUnit } from 'utils/money'
import { convertCustomerIsNewToNumber } from './utils'

const AFFILIATION_B2C_PHYSICAL_CHECKOUT = 'B2C Physical Books Checkout'

export const purchaseEvent = (cart, giftWrapOption) => {
  return {
    event: 'purchase',
    // GA4 data
    transaction_id: cart.order_info ? cart.order_info.order_id : null,
    affiliation: AFFILIATION_B2C_PHYSICAL_CHECKOUT,
    value: convertUnit(cart.amount_to_pay_no_tax).toString(), // Exclusive VAT.
    shipping: convertUnit(cart.freight_total_amount_no_tax).toString(),
    tax: convertUnit(cart.total_tax_amount).toString(),
    price_to_pay: convertUnit(cart.total).toString(),
    coupon: cart.campaign,
    customerIsNew: convertCustomerIsNewToNumber(cart.customer_new),
    customerIsLoggedIn: cart.order_info.username ? 1 : 0,
    customerType: 'Privat',
    customerHasAgreement: 0,
    customerEmail: cart.order_info.email,
    customerPhone: cart.order_info.billing_address.phone,
    emailHashed: cart.order_info.email_hash,
    tradedoublerChecksum: cart.order_info.tradedouble_checksum,
    items: createItemsArray(cart, giftWrapOption),
    voyadoCartRef: cart.voyado_cart_ref,
  }
}

export const createItemsArray = (cart, giftWrapOption) => {
  let gtmProductArray = []
  if (cart.products) {
    gtmProductArray = cart.products.reduce((accumulator, currentValue) => {
      const discount = parseFloat(
        convertUnit(
          calculateDiscount({
            price: currentValue.price,
            totalPrice: currentValue.total_price,
            quantity: currentValue.quantity,
            taxRate: currentValue.tax_rate,
          })
        )
      )
      accumulator.push({
        item_id: currentValue.sku,
        item_name: currentValue.title,
        discount: discount === 0 ? 0 : discount,
        item_brand: currentValue.extra.author,
        item_category: 'Book',
        item_category2: currentValue.extra.categories_level1,
        item_category3: currentValue.extra.categories_level2,
        item_variant: currentValue.extra.binding
          ? currentValue.extra.binding
          : currentValue.extra.binding_unnormalized,
        item_status: currentValue.extra.status,
        item_reading_age: currentValue.extra.age_group,
        item_series: currentValue.extra.series,
        price: parseFloat(convertUnit(currentValue.price_no_tax)), // Unit price what the customer will pay.
        quantity: currentValue.quantity,
        item_publisher: currentValue.extra.publisher,
        item_delivery: currentValue.extra.delivery_time_single,
        item_tags: currentValue.extra.campaign_tags,
        price_to_pay: convertUnit(currentValue.total_price).toString(),
      })
      // Check if product is giftwrapped, if so add data to the result.
      if (giftWrapOption && !!currentValue.gw) {
        accumulator.push({
          item_id: currentValue.sku + '_GIFTWRAP',
          item_name: 'Presentinslagning',
          item_category: 'Service',
          price: parseFloat(
            convertUnit(giftWrapOption.price - giftWrapOption.vat)
          ),
          quantity: currentValue.quantity,
          price_to_pay: convertUnit(currentValue.gwprice).toString(),
        })
      }

      return accumulator
    }, [])
  }
  return gtmProductArray
}
