import React from 'react'

const ClearIcon = props => {
  let { width, height, color } = props

  width = width ? width : 20
  height = height ? height : 20
  color = color ? color : '#777777'

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8334 5.34163L14.6584 4.16663L10.0001 8.82496L5.34175 4.16663L4.16675 5.34163L8.82508 9.99996L4.16675 14.6583L5.34175 15.8333L10.0001 11.175L14.6584 15.8333L15.8334 14.6583L11.1751 9.99996L15.8334 5.34163Z"
        fill={color}
      />
    </svg>
  )
}

export default ClearIcon
