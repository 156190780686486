import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'

import { SET_STUDENT_START } from './constants'
import { setStudentSuccess, setStudentFailure } from './actions'

function* sagaSetStudentStart(action) {
  const state = yield select()

  try {
    yield call(
      request,
      endpoints.createSetStudentUrl(state.session.sessionId),
      'PUT',
      { student: action.checked }
    )

    yield put(setStudentSuccess(action.checked))
  } catch (e) {
    yield put(setStudentFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(SET_STUDENT_START, sagaSetStudentStart)])
}
