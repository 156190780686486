import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TagClasses from 'utils/TagClasses'
import { translate } from 'utils/language'
import SpinnerIcon from 'components/Icons/SpinnerIcon'

import './style.scss'

export default class Spinner extends Component {
  static propTypes = {
    position: PropTypes.string,
    type: PropTypes.oneOf(['dark', 'light']),
    size: PropTypes.string,
  }

  static defaultProps = {
    type: 'dark',
    size: 'medium',
  }

  render() {
    const { position, type, size } = this.props

    let classes = new TagClasses([`spinner`, `spinner__type__${type}`])

    if (position) {
      classes.add(`spinner--${position}`)
    }

    return (
      <div className={classes}>
        <div className="spinner__loading-indicator" role="status">
          <SpinnerIcon size={size} />
          <span className="sr-only">{translate('LOADING')}</span>
        </div>
      </div>
    )
  }
}
