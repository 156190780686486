const CSEK = 'CSEK'
const SEK = 'SEK'

const defaults = {
  fromUnit: CSEK,
  toUnit: SEK,
  currency: SEK,
}

const unitRatios = {
  CSEK: 1,
  SEK: 0.01,
}

const currencySignByCurrency = {
  SEK: 'kr',
}

const unitPriceSign = {
  SEK: 'st',
}

export function displayPrice(value, options) {
  if (value === null || value === undefined) {
    return ''
  }

  options = Object.assign({}, defaults, options)

  let result = convertUnit(value, options)
  result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Thousands separators
  result = result.replace('.', ',')

  let currencySign = options.currencySign
    ? options.currencySign
    : currencySignByCurrency[options.currency]
  result = `${result} ${currencySign}`

  if (options.unitPrice) {
    result = `${result}/${unitPriceSign[options.currency]}`
  }
  return result
}

export function convertUnit(value, options) {
  options = Object.assign({}, defaults, options)
  let result = value * getUnitRatios(options.fromUnit, options.toUnit)

  if (value % 100 !== 0) {
    result = parseFloat(result).toFixed(2)
  }

  return result
}

export function getUnitRatios(from, to) {
  return unitRatios[to] * (1 / unitRatios[from])
}

export function reduceAmountWithOneSek(amount) {
  return amount - 100
}

export function calculateDiscount({ price, totalPrice, quantity, taxRate }) {
  return (price - totalPrice / quantity) * (1 - taxRate / 10000)
}
