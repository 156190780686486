import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'

import {
  ADD_CAMPAIGN_CODE_START,
  DELETE_CAMPAIGN_CODE_START,
} from './constants'

import {
  addCampaignCodeSuccess,
  addCampaignCodeFailure,
  deleteCampaignCodeSuccess,
  deleteCampaignCodeFailure,
} from './actions'

function* sagaAddCampaignCodeStart(action) {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createAddCampaignCodeUrl(state.session.sessionId),
      'PUT',
      { campaign_code: action.campaignCode }
    )

    yield put(addCampaignCodeSuccess(response))
  } catch (e) {
    yield put(addCampaignCodeFailure(e))
  }
}

function* sagaDeleteCampaignCodeStart(action) {
  const state = yield select()
  try {
    const response = yield call(
      request,
      endpoints.createDeleteCampaignCodeUrl(
        state.session.sessionId,
        action.campaignCodeId
      ),
      'DELETE'
    )

    yield put(deleteCampaignCodeSuccess(response))
  } catch (e) {
    yield put(deleteCampaignCodeFailure(e))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ADD_CAMPAIGN_CODE_START, sagaAddCampaignCodeStart),
    takeLatest(DELETE_CAMPAIGN_CODE_START, sagaDeleteCampaignCodeStart),
  ])
}
