import {
  all,
  call,
  cancelled,
  put,
  takeLatest,
  delay,
  select,
} from 'redux-saga/effects'
import { request } from 'utils/request'

import { UPDATE_CART_DELAY } from 'utils/config'

import {
  GET_CART_START,
  REFRESH_SHIPPING_START,
  UPDATE_CART_ITEM_WATCHER,
  UPDATE_CART_ITEM_START,
} from './constants'
import { LOGIN_SUCCESS } from 'containers/Login/constants'
import { LOGOUT_SUCCESS } from 'containers/Login/Logout/constants'
import endpoints from 'api/endpoints'
import { translate } from 'utils/language'
import { showWarningSticky } from 'containers/WarningSticky/actions'

import {
  getCartSuccess,
  getCartFailure,
  refreshShippingSuccess,
  refreshShippingFailure,
  showRemoveCartItemDialog,
  updateCartItemStart,
  updateCartItemSuccess,
  updateCartItemFailure,
} from './actions'

export function* sagaGetCartStart() {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createGetCartUrl(state.session.sessionId),
      'GET'
    )
    yield put(getCartSuccess(response))
  } catch (e) {
    yield put(getCartFailure(e))
  }
}

export function* sagaRefreshShippingStart() {
  const state = yield select()
  const sessionId = state.session.sessionId
  const controller = new AbortController()
  const { signal } = controller

  try {
    const response = yield call(
      request,
      endpoints.createRefreshShippingUrl(sessionId),
      'GET',
      null,
      signal
    )

    if (sessionId && response.country !== state.cart.data.country) {
      yield put(
        showWarningSticky(translate('KLARNA_CHECKOUT_COUNTRY_CHANGE_INFO'))
      )
    }
    yield put(refreshShippingSuccess(response))
  } catch (e) {
    yield put(refreshShippingFailure(e))
  } finally {
    if (yield cancelled()) controller.abort()
  }
}

export function* sagaUpdateCartItemStart(action) {
  const state = yield select()
  try {
    const response = yield call(
      request,
      endpoints.createUpdateCartItemUrl(
        state.session.sessionId,
        action.productId
      ),
      'PUT',
      {
        quantity: action.quantity,
      }
    )
    yield put(updateCartItemSuccess(action.productId, response))
  } catch (e) {
    yield put(updateCartItemFailure(action.productId, e))
  }
}

export function* sagaUpdateCartItemWatcher(action) {
  yield delay(UPDATE_CART_DELAY)
  const state = yield select()
  const product = state.cart.data.products.find(product => {
    return product.product_id === action.productId
  })

  if (action.quantity > 0 && product.quantity !== action.quantity) {
    yield put(updateCartItemStart(action.productId, action.quantity))
  } else if (action.quantity === 0) {
    yield put(showRemoveCartItemDialog(action.productId, action.quantity))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_CART_START, sagaGetCartStart),
    takeLatest(REFRESH_SHIPPING_START, sagaRefreshShippingStart),
    takeLatest(LOGIN_SUCCESS, sagaGetCartStart),
    takeLatest(LOGOUT_SUCCESS, sagaGetCartStart),
    takeLatest(UPDATE_CART_ITEM_WATCHER, sagaUpdateCartItemWatcher),
    takeLatest(UPDATE_CART_ITEM_START, sagaUpdateCartItemStart),
  ])
}
