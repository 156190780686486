import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { request } from 'utils/request'

import endpoints from 'api/endpoints'
import { getGiftWrapOptionsFailure, getGiftWrapOptionsSuccess } from './actions'
import { GET_GIFT_WRAP_OPTIONS_START } from './constants'

export function* sagaGetGiftWrapStart() {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createGiftWrapUrl(state.session.sessionId),
      'GET'
    )
    yield put(getGiftWrapOptionsSuccess(response))
  } catch (e) {
    yield put(getGiftWrapOptionsFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(GET_GIFT_WRAP_OPTIONS_START, sagaGetGiftWrapStart)])
}
