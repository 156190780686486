import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  INIT_AUTH_START,
  INIT_AUTH_SUCCESS,
  INIT_AUTH_FAILURE,
} from './constants'

import { ADD_PERSONAL_NUMBER_SUCCESS } from 'containers/Pages/Checkout/PersonalNumber/constants'
import { LOGIN_SUCCESS } from 'containers/Login/constants'
import { LOGOUT_SUCCESS } from 'containers/Login/Logout/constants'

const initialState = Immutable({
  user: null,
  loading: false,
  error: null,
  finished: false,
})

const Reducer = createReducer(initialState, {
  [INIT_AUTH_START](state) {
    return state
      .set('loading', true)
      .set('user', null)
      .set('error', null)
  },
  [INIT_AUTH_SUCCESS](state, { user }) {
    return state
      .set('loading', false)
      .set('user', user)
      .set('error', null)
      .set('finished', true)
  },
  [INIT_AUTH_FAILURE](state, { error }) {
    return state
      .set('error', error)
      .set('loading', false)
      .set('user', null)
      .set('finished', true)
  },
  [ADD_PERSONAL_NUMBER_SUCCESS](state) {
    return state.setIn(['user', 'has_civic_number'], true)
  },
  [LOGIN_SUCCESS](state, { user }) {
    return state.set('user', user)
  },
  [LOGOUT_SUCCESS](state) {
    return state.set('user', null)
  },
})

export default Reducer
