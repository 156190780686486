import {
  INIT_AUTH_START,
  INIT_AUTH_SUCCESS,
  INIT_AUTH_FAILURE,
} from './constants'

export const initAuthStart = () => ({
  type: INIT_AUTH_START,
})

export const initAuthSuccess = user => ({
  type: INIT_AUTH_SUCCESS,
  user,
})

export const initAuthFailure = error => ({
  type: INIT_AUTH_FAILURE,
  error,
})
