import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'

import { getResetOrderStart } from '../ResetOrder/actions'

import { ADD_PERSONAL_NUMBER_START } from './constants'
import { addPersonalNumberSuccess, addPersonalNumberFailure } from './actions'

function* sagaAddPersonalNumberStart(action) {
  const state = yield select()

  try {
    yield call(
      request,
      endpoints.createAddPersonalNumberUrl(state.session.sessionId),
      'PATCH',
      { personal_identification_number: action.personalNumber }
    )

    yield put(addPersonalNumberSuccess())
    yield put(getResetOrderStart())
  } catch (e) {
    yield put(addPersonalNumberFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(ADD_PERSONAL_NUMBER_START, sagaAddPersonalNumberStart)])
}
