import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getParameter } from 'utils/common'
import { request } from 'utils/request'

import { GET_RESET_ORDER_START } from './constants'

import endpoints from 'api/endpoints'

import { getResetOrderSuccess, getResetOrderFailure } from './actions'

export function* sagaResetOrderStart() {
  const student = getParameter('student')
  const state = yield select()
  const sessionId = state.session.sessionId

  try {
    const response = yield call(
      request,
      endpoints.createResetOrderUrl(sessionId, student),
      'GET'
    )
    yield put(getResetOrderSuccess(response))
  } catch (e) {
    yield put(getResetOrderFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(GET_RESET_ORDER_START, sagaResetOrderStart)])
}
