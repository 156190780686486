import {
  SET_STUDENT_START,
  SET_STUDENT_SUCCESS,
  SET_STUDENT_FAILURE,
} from './constants'

export const setStudentStart = checked => ({
  type: SET_STUDENT_START,
  checked,
})

export const setStudentSuccess = checked => ({
  type: SET_STUDENT_SUCCESS,
  checked,
})

export const setStudentFailure = error => ({
  type: SET_STUDENT_FAILURE,
  error,
})
