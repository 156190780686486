import {
  GET_GIFT_WRAP_OPTIONS_FAILURE,
  GET_GIFT_WRAP_OPTIONS_START,
  GET_GIFT_WRAP_OPTIONS_SUCCESS,
} from './constants'

export const getGiftWrapOptionsStart = () => ({
  type: GET_GIFT_WRAP_OPTIONS_START,
})

export const getGiftWrapOptionsSuccess = response => {
  return {
    type: GET_GIFT_WRAP_OPTIONS_SUCCESS,
    giftWrap: response,
  }
}

export const getGiftWrapOptionsFailure = error => ({
  type: GET_GIFT_WRAP_OPTIONS_FAILURE,
  error,
})
