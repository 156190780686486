import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  GIFT_CERTIFICATE_TOGGLE,
  UPDATE_GIFT_CERTIFICATE,
  ADD_GIFT_CERTIFICATE_START,
  ADD_GIFT_CERTIFICATE_SUCCESS,
  ADD_GIFT_CERTIFICATE_FAILURE,
  GIFT_CERTIFICATE_CLEAR_ERROR,
  GIFT_CERTIFICATE_SET_VALIDATED,
  DELETE_GIFT_CERTIFICATE_START,
  DELETE_GIFT_CERTIFICATE_SUCCESS,
  DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
} from './constants'

const initialState = Immutable({
  error: null,
  loading: false,
  value: '',
  showForm: false,
  validated: false,
  deleteGiftCertificates: null,
})

const Reducer = createReducer(initialState, {
  [GIFT_CERTIFICATE_TOGGLE](state) {
    return state.set('showForm', !state.showForm)
  },
  [UPDATE_GIFT_CERTIFICATE](state, { giftCertificate }) {
    return state.set('value', giftCertificate)
  },
  [ADD_GIFT_CERTIFICATE_START](state) {
    return state.set('error', null).set('loading', true)
  },
  [ADD_GIFT_CERTIFICATE_SUCCESS](state) {
    return state
      .set('error', null)
      .set('loading', false)
      .set('showForm', false)
      .set('value', '')
  },
  [ADD_GIFT_CERTIFICATE_FAILURE](state, { error }) {
    return state.set('error', error).set('loading', false)
  },
  [GIFT_CERTIFICATE_CLEAR_ERROR](state) {
    return state.set('error', null)
  },
  [GIFT_CERTIFICATE_SET_VALIDATED](state, { validated }) {
    return state.set('validated', validated)
  },
  [DELETE_GIFT_CERTIFICATE_START](state, { giftCertificateId }) {
    return state
      .setIn(['deleteGiftCertificates', giftCertificateId, 'error'], null)
      .setIn(['deleteGiftCertificates', giftCertificateId, 'loading'], true)
  },
  [DELETE_GIFT_CERTIFICATE_SUCCESS](state, { giftCertificateId }) {
    return state
      .setIn(['deleteGiftCertificates', giftCertificateId, 'error'], null)
      .setIn(['deleteGiftCertificates', giftCertificateId, 'loading'], false)
  },
  [DELETE_GIFT_CERTIFICATE_CODE_FAILURE](state, { giftCertificateId, error }) {
    return state
      .setIn(['deleteGiftCertificates', giftCertificateId, 'error'], error)
      .setIn(['deleteGiftCertificates', giftCertificateId, 'loading'], false)
  },
})

export default Reducer
