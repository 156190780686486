import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'

import {
  ADD_GIFT_CERTIFICATE_START,
  DELETE_GIFT_CERTIFICATE_START,
} from './constants'

import {
  addGiftCertificateSuccess,
  addGiftCertificateFailure,
  giftCertificateSetValidated,
  deleteGiftCertificateSuccess,
  deleteGiftCertificateFailure,
} from './actions'

function* sagaAddGiftCertificateStart(action) {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createAddGiftCertificateUrl(state.session.sessionId),
      'POST',
      { gift_certificate_code: action.giftCertificate }
    )

    yield put(addGiftCertificateSuccess(response))
    yield put(giftCertificateSetValidated(false))
  } catch (e) {
    yield put(addGiftCertificateFailure(e))
  }
}

function* sagaDeleteGiftCertificateStart(action) {
  const state = yield select()
  try {
    const response = yield call(
      request,
      endpoints.createDeleteGiftCertificateUrl(
        state.session.sessionId,
        action.giftCertificateId
      ),
      'DELETE'
    )

    yield put(deleteGiftCertificateSuccess(action.giftCertificateId, response))
  } catch (e) {
    yield put(deleteGiftCertificateFailure(action.giftCertificateId, e))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ADD_GIFT_CERTIFICATE_START, sagaAddGiftCertificateStart),
    takeEvery(DELETE_GIFT_CERTIFICATE_START, sagaDeleteGiftCertificateStart),
  ])
}
