import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getParameter } from 'utils/common'
import { request } from 'utils/request'

import {
  GET_RESET_ORDER_START,
  GET_RESET_ORDER_SUCCESS,
  GET_RESET_ORDER_FAILURE,
} from '../ResetOrder/constants'
import {
  GET_CART_START,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  UPDATE_CART_ITEM_START,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_CART_ITEM_FAILURE,
  REFRESH_SHIPPING_FAILURE,
  REFRESH_SHIPPING_SUCCESS,
  REFRESH_SHIPPING_START,
} from '../Cart/constants'
import {
  ADD_CAMPAIGN_CODE_START,
  ADD_CAMPAIGN_CODE_SUCCESS,
  ADD_CAMPAIGN_CODE_FAILURE,
  DELETE_CAMPAIGN_CODE_START,
  DELETE_CAMPAIGN_CODE_SUCCESS,
  DELETE_CAMPAIGN_CODE_FAILURE,
} from '../Cart/CampaignCode/constants'
import {
  ADD_GIFT_CERTIFICATE_START,
  ADD_GIFT_CERTIFICATE_SUCCESS,
  ADD_GIFT_CERTIFICATE_FAILURE,
  DELETE_GIFT_CERTIFICATE_START,
  DELETE_GIFT_CERTIFICATE_SUCCESS,
  DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
} from '../Cart/GiftCertificate/constants'
import {
  SET_NEWSLETTER_START,
  SET_NEWSLETTER_SUCCESS,
  SET_NEWSLETTER_FAILURE,
} from '../Newsletter/constants'
import {
  ADD_PERSONAL_NUMBER_START,
  ADD_PERSONAL_NUMBER_SUCCESS,
  ADD_PERSONAL_NUMBER_FAILURE,
} from '../PersonalNumber/constants'
import {
  GET_CHECKOUT_SNIPPET_START,
  SHIPPING_ADDRESS_CHANGE_START,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_START,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE,
} from './constants'

import endpoints from 'api/endpoints'

import {
  setDeliveryAddressCountryIsoStart,
  setDeliveryAddressCountryIsoSuccess,
  setDeliveryAddressCountryIsoFailure,
  addSuspendAction,
  deleteSuspendAction,
  getCheckoutSnippetSuccess,
  getCheckoutSnippetFailure,
  kcoSuspend,
  kcoResume,
} from './actions'

export function* sagaGetSnippetStart() {
  const student = getParameter('student')
  const state = yield select()
  const sessionId = state.session.sessionId

  try {
    const response = yield call(
      request,
      endpoints.createResetOrderUrl(sessionId, student),
      'GET'
    )
    yield put(getCheckoutSnippetSuccess(response))
  } catch (e) {
    yield put(getCheckoutSnippetFailure(e))
  }
}

export function* sagaKCOSuspend() {
  yield put(kcoSuspend())
}

export function* sagaKCOResume() {
  yield put(kcoResume())
}

export function* sagaAddSuspendAction(action) {
  const baseActionType = action.type.replace('_START', '')

  yield put(addSuspendAction(baseActionType))
}

export function* sagaDeleteSuspendAction(action) {
  const baseActionType = action.type
    .replace('_SUCCESS', '')
    .replace('_FAILURE', '')

  yield put(deleteSuspendAction(baseActionType))
}

export function* sagaShippingAddressChangeStart(action) {
  const state = yield select()
  const sessionId = state.session.sessionId

  // Sometimes Klarna fires same event twice.
  // We avoid duplicated api calls by our country check.
  if (sessionId && action.data.country !== state.cart.data.country) {
    yield put(setDeliveryAddressCountryIsoStart(action.data.country))

    try {
      const response = yield call(
        request,
        endpoints.createSetDeliveryAddressCountryIso(
          sessionId,
          action.data.country
        ),
        'PUT'
      )

      yield put(setDeliveryAddressCountryIsoSuccess(response))
    } catch (e) {
      yield put(setDeliveryAddressCountryIsoFailure(e))
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(SHIPPING_ADDRESS_CHANGE_START, sagaShippingAddressChangeStart),
    takeLatest(GET_CHECKOUT_SNIPPET_START, sagaGetSnippetStart),
    takeLatest(
      [
        ADD_CAMPAIGN_CODE_START,
        DELETE_CAMPAIGN_CODE_START,
        ADD_GIFT_CERTIFICATE_START,
        DELETE_GIFT_CERTIFICATE_START,
        GET_CART_START,
        SET_NEWSLETTER_START,
        UPDATE_CART_ITEM_START,
        ADD_PERSONAL_NUMBER_START,
        SET_DELIVERY_ADDRESS_COUNTRY_ISO_START,
        GET_RESET_ORDER_START,
        REFRESH_SHIPPING_START,
      ],
      sagaAddSuspendAction
    ),
    takeLatest(
      [
        ADD_CAMPAIGN_CODE_SUCCESS,
        ADD_CAMPAIGN_CODE_FAILURE,
        DELETE_CAMPAIGN_CODE_SUCCESS,
        DELETE_CAMPAIGN_CODE_FAILURE,
        ADD_GIFT_CERTIFICATE_SUCCESS,
        ADD_GIFT_CERTIFICATE_FAILURE,
        DELETE_GIFT_CERTIFICATE_SUCCESS,
        DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
        GET_CART_SUCCESS,
        GET_CART_FAILURE,
        SET_NEWSLETTER_SUCCESS,
        SET_NEWSLETTER_FAILURE,
        UPDATE_CART_ITEM_SUCCESS,
        UPDATE_CART_ITEM_FAILURE,
        ADD_PERSONAL_NUMBER_SUCCESS,
        ADD_PERSONAL_NUMBER_FAILURE,
        SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS,
        SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE,
        GET_RESET_ORDER_SUCCESS,
        GET_RESET_ORDER_FAILURE,
        REFRESH_SHIPPING_SUCCESS,
        REFRESH_SHIPPING_FAILURE,
      ],
      sagaDeleteSuspendAction
    ),
  ])
}
