import getEnvConfig from 'utils/getEnvConfig'

export const DEFAULT_PAGE_PATH = getEnvConfig('DEFAULT_PAGE_PATH')
export const CHECKOUT_PAGE_PATH = getEnvConfig('CHECKOUT_PAGE_PATH')
export const CHECKOUT_PAGE_PATH_VARIANT = getEnvConfig(
  'CHECKOUT_PAGE_PATH_VARIANT'
)
export const CHECKOUT_CONFIRMATION_PAGE_PATH = getEnvConfig(
  'CHECKOUT_CONFIRMATION_PAGE_PATH'
)
export const CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH = getEnvConfig(
  'CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH'
)
export const LOGIN_PAGE_PATH = getEnvConfig('LOGIN_PAGE_PATH')
export const COMPLEX_CHECKOUT_PATH = getEnvConfig('COMPLEX_CHECKOUT_PATH')
export const FORGOT_PASSWORD_PAGE_PATH = getEnvConfig(
  'FORGOT_PASSWORD_PAGE_PATH'
)
export const START_PAGE = getEnvConfig('START_PAGE')
