import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'
import { GET_SESSION_ID_SUCCESS } from 'containers/Session/constants'

import { initAuthSuccess, initAuthFailure } from './actions'

export function* sagaInitAuthStart() {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createGetCustomerUrl(state.session.sessionId),
      'GET'
    )

    yield put(initAuthSuccess(response))
  } catch (e) {
    yield put(initAuthFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(GET_SESSION_ID_SUCCESS, sagaInitAuthStart)])
}
