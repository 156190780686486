import {
  GET_SESSION_ID_START,
  GET_SESSION_ID_SUCCESS,
  GET_SESSION_ID_FAILURE,
} from './constants'

export const getSessionIdStart = fromCookie => ({
  type: GET_SESSION_ID_START,
  fromCookie: !!fromCookie,
})

export const getSessionIdSuccess = sessionId => ({
  type: GET_SESSION_ID_SUCCESS,
  sessionId,
})

export const getSessionIdFailure = error => ({
  type: GET_SESSION_ID_FAILURE,
  error,
})
