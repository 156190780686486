import { all, put, takeLatest } from 'redux-saga/effects'
import Cookies from 'js-cookie'

import { GET_SESSION_ID_START } from './constants'
import { getSessionIdSuccess, getSessionIdFailure } from './actions'
import { translate } from 'utils/language'
import getEnvConfig from 'utils/getEnvConfig'

const SESSION_COOKIE_NAME = 'LemaTempID'
const ENVIRONMENT_MODE = getEnvConfig('MODE')

export function* sagaGetSessionIdStart({ fromCookie }) {
  try {
    let sessionId = null

    if (fromCookie) {
      // Get the session id from a cookie
      sessionId = Cookies.get(SESSION_COOKIE_NAME)
    } else {
      // Get the session id from environment variable
      sessionId = process.env.REACT_APP_SESSION_ID
    }

    if (sessionId) {
      // Strip last exclamation sign.
      sessionId = sessionId.replace(/!$/, '')

      yield put(getSessionIdSuccess(sessionId))
    } else {
      let noSessionIdString = translate('NO_SESSION_ID_FOUND')
      if (fromCookie === false && ENVIRONMENT_MODE === 'local') {
        noSessionIdString +=
          '. Check the README.md for .env.local and REACT_APP_SESSION_ID.'
      }
      yield put(getSessionIdFailure(new Error(noSessionIdString)))
    }
  } catch (error) {
    yield put(getSessionIdFailure(error))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(GET_SESSION_ID_START, sagaGetSessionIdStart)])
}
