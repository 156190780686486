import { all, takeLatest, call } from 'redux-saga/effects'

import { START_PAGE } from 'components/App/constants'
import {
  GET_CHECKOUT_SNIPPET_FAILURE,
  SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE,
} from '../KlarnaCheckout/constants'
import { UPDATE_CART_ITEM_FAILURE, GET_CART_FAILURE } from '../Cart/constants'
import {
  DELETE_CAMPAIGN_CODE_FAILURE,
  ADD_CAMPAIGN_CODE_FAILURE,
} from '../Cart/CampaignCode/constants'
import {
  DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
  ADD_GIFT_CERTIFICATE_FAILURE,
} from '../Cart/GiftCertificate/constants'
import { ADD_PERSONAL_NUMBER_FAILURE } from '../PersonalNumber/constants'
import { GET_RESET_ORDER_FAILURE } from '../ResetOrder/constants'
import { SET_NEWSLETTER_FAILURE } from '../Newsletter/constants'
import { GET_CONFIRMATION_CART_FAILURE } from '../ConfirmationPage/ConfirmationCart/constants'
import { navigateTo } from 'utils/location'

export function* sagaStartPageRedirect(action) {
  // If the users LemaTempId cookie has been removed an error with status code 404
  // and error code 4 is returned (Session not found) since the new id is empty
  // of session data. A fix for this is to return the user to the start page which
  // will restore the users cart and session.
  // https://bokus1.atlassian.net/browse/KCO-1258'
  if (
    action.error &&
    action.error.response &&
    action.error.json &&
    action.error.response.status === 404 &&
    action.error.json.code === 4
  ) {
    yield call(navigateTo, START_PAGE)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        ADD_CAMPAIGN_CODE_FAILURE,
        ADD_GIFT_CERTIFICATE_FAILURE,
        ADD_PERSONAL_NUMBER_FAILURE,
        DELETE_CAMPAIGN_CODE_FAILURE,
        DELETE_CAMPAIGN_CODE_FAILURE,
        DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
        DELETE_GIFT_CERTIFICATE_CODE_FAILURE,
        GET_CART_FAILURE,
        GET_CHECKOUT_SNIPPET_FAILURE,
        GET_RESET_ORDER_FAILURE,
        SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE,
        SET_NEWSLETTER_FAILURE,
        UPDATE_CART_ITEM_FAILURE,
        UPDATE_CART_ITEM_FAILURE,
        GET_CONFIRMATION_CART_FAILURE,
      ],
      sagaStartPageRedirect
    ),
  ])
}
