import { SHOW_WARNING_STICKY, HIDE_WARNING_STICKY } from './constants'

export const showWarningSticky = text => ({
  type: SHOW_WARNING_STICKY,
  text,
})

export const hideWarningSticky = () => ({
  type: HIDE_WARNING_STICKY,
})
