import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { cartEvent } from './dataLayer/cartEvent'
import { confirmationEvent } from './dataLayer/confirmationEvent'
import {
  CHECKOUT_PAGE_PATH,
  CHECKOUT_PAGE_PATH_VARIANT,
  CHECKOUT_CONFIRMATION_PAGE_PATH,
  CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH,
} from 'components/App/constants'
import { purchaseEvent } from './dataLayer/purchaseEvent'
import { createUndeliverableEvent } from './dataLayer/undeliverableEvent'
import { basketEmptiedEvent } from './dataLayer/basketEmptied'
import { basketResetEvent } from './dataLayer/basketReset'
import { removeFromBasketEvent } from './dataLayer/removeFromBasket'
import { decreaseBasketEvent } from './dataLayer/decreaseBasketEvent'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    authFinished: state.auth.finished,
    cart: state.cart.data,
    giftWrapOptions: state.giftWrapOptions.options,
    selectedGiftWrapOption: state.giftWrapOptions.ui.selectedOption,
  }
}

export const pushDataToDataLayer = data => {
  if (window.dataLayer) window.dataLayer.push(data)
}

export const shouldDispatchCartEvent = (prevProps, props) => {
  return prevProps.cart !== props.cart && !!props.cart.freight_ksa
}

export const shouldDispatchConfirmationEvent = (prevProps, props) => {
  const giftWrapOptions = props.giftWrapOptions
  const cart = props.cart
  const prevGiftWrapOptions = prevProps.giftWrapOptions
  const prevCart = prevProps.cart

  return giftWrapOptions && cart.session_id
    ? prevGiftWrapOptions !== giftWrapOptions || prevCart !== cart
    : false
}

class GoogleTagManager extends React.Component {
  static propTypes = {
    gtmId: PropTypes.string.isRequired,
    dataLayerName: PropTypes.string,
    additionalEvents: PropTypes.object,
    previewVariables: PropTypes.string,
    scriptId: PropTypes.string,
    scheme: PropTypes.string,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidUpdate(prevProps) {
    this.calculateCreateAndPushEvent(
      this.props.location.pathname,
      prevProps,
      this.props
    )
  }

  calculateCreateAndPushEvent(pathname, prevProps, props) {
    let events = []
    switch (pathname) {
      case CHECKOUT_PAGE_PATH:
      case CHECKOUT_PAGE_PATH_VARIANT:
        if (shouldDispatchCartEvent(prevProps, props)) {
          events.push(basketResetEvent())
          events.push(cartEvent(this.props.cart))
          if (prevProps.cart && this.props.cart) {
            events.push(
              this.dispatchRemoveFromBasketEvent(
                prevProps.cart,
                this.props.cart
              )
            )
          }
        }
        break
      case CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH:
        if (props.cart.products) {
          events.push(createUndeliverableEvent(props.cart))
        }
        break
      case CHECKOUT_CONFIRMATION_PAGE_PATH:
        if (shouldDispatchConfirmationEvent(prevProps, props)) {
          events.push(this.createBasketEmptiedEvent(props))
          events.push(this.createPurchaseEvent(props))
        }
        break
      default:
        break
    }

    if (events.length > 0) {
      events.forEach(event => {
        pushDataToDataLayer(event)
      })
    }
  }

  createConfirmationEvent(props) {
    const { giftWrapOptions, selectedGiftWrapOption, cart } = props
    const giftWrapOption =
      giftWrapOptions.length > 0
        ? giftWrapOptions[selectedGiftWrapOption]
        : null
    return confirmationEvent(cart, giftWrapOption)
  }

  createPurchaseEvent(props) {
    const { giftWrapOptions, selectedGiftWrapOption, cart } = props
    const giftWrapOption =
      giftWrapOptions.length > 0
        ? giftWrapOptions[selectedGiftWrapOption]
        : null
    return purchaseEvent(cart, giftWrapOption)
  }

  createBasketEmptiedEvent(props) {
    const { cart } = props
    return basketEmptiedEvent(cart)
  }

  dispatchRemoveFromBasketEvent(prevCart, cart) {
    if (prevCart.products && cart.products) {
      if (this.hasProductsRemoved(prevCart.products, cart.products)) {
        return removeFromBasketEvent(prevCart, cart)
      } else if (this.hasQuantityDecreased(prevCart.products, cart.products)) {
        return decreaseBasketEvent(prevCart, cart)
      }
    }
  }

  hasProductsRemoved(prevProducts, products) {
    if (prevProducts && products) {
      return prevProducts.length > products.length
    }
  }

  hasQuantityDecreased(prevProducts, products) {
    if (prevProducts && products) {
      return prevProducts.some(prevProduct =>
        this.hasDecreased(products, prevProduct)
      )
    }
  }

  hasDecreased(products, prevProduct) {
    return products.some(
      item =>
        item.product_id === prevProduct.product_id &&
        prevProduct.quantity > item.quantity
    )
  }

  render() {
    return null
  }
}
export default withRouter(connect(mapStateToProps)(GoogleTagManager))
