import { convertUnit } from 'utils/money'
import {
  createItems,
  removedProducts,
  valueOfRemovedItems,

} from './utils'

export const removeFromBasketEvent = (prevCart, cart) => {
  const products = removedProducts(prevCart.products, cart.products)
  const items = createItems(products)
  const value = convertUnit(valueOfRemovedItems(products)) 

  let result = {
      event: 'remove_from_cart',
      value: value,
      items: items,
  }
  return result
}