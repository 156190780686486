const STRINGS = {
  /* Cart */
  CART: 'Varukorg',
  CART_IS_BEING_COLLECTED: 'Din varukorg hämtas.',
  YOUR_CART_IS_EMPTY: 'Din varukorg är tom.',
  YOU_ARE_BEING_REDIRECTED_TO_OUR_HOME_PAGE:
    'Du omdirigeras nu till vår hemsida.',
  SUM_VAT_INCLUDED: 'Totalt inkl. moms',
  SUM_VAT_EXCLUDED: 'Totalt exkl. moms',
  FREE_FREIGHT_ABOVE: 'Fri frakt inom Sverige över',
  LEFT: 'kvar',
  ONLY_INDIVIDUALS: 'Gäller endast privatpersoner.',
  REMOVE_FROM_CART: 'Ta bort produkten ur varukorgen?',
  CAMPAIGN_CODE: 'Kampanjkod',
  USE_CAMPAIGN_CODE: 'Använd kampanjkod',
  FREIGHT: 'Frakt',
  FREIGHT_CHARGE: 'Fraktavgift',
  REDUCTION: 'Tillgodo',
  CREATE_PRIVATE_ACCOUNT: 'Skapa privatkonto',
  DISCOUNT_MEDMERA_HEADER: 'Bokus Pluspris',
  DISCOUNT_PLAY_HEADER: 'Bokus Play',
  DISCOUNT_DISCOUNTED_HEADER: 'Ditt pris',
  DISCOUNT_CAMPAIGN_PRICE_HEADER: 'Kampanjpris',
  IMPORTANT_CHANGES_LIST_TITLE: 'Viktig information om din beställning',
  DELIVERY_TIME_MESSAGE_SPLIT_CART_HEADER: 'Varor med olika leveranstider',
  DELIVERY_TIME_MESSAGE_SPLIT_CART:
    'Varorna inväntar varandra och skickas tillsammans inom {0} vardagar.',
  DELIVERY_TIME_MESSAGE_SPLIT_CART_PRE_ORDER:
    'Varorna inväntar varandra och skickas tillsammans om {0} vardagar.',
  PRICE_CHANGES_LIST_TITLE: 'En eller flera varor har fått nytt pris',
  POSTEN_SITE: 'PostNords hemsida',
  PLUS_PRICE_INFO: 'om du vill ta del av Pluspris-erbjudanden i varukorgen.',
  ADD_GIFT_WRAPPING: 'Lägg till presentinslagning',
  ADD_GIFT_WRAPPING_TO: 'Lägg till presentinslagning till',
  PRODUCT_QUANTITY_TITLE: '{0} st {1}',
  WOULD_YOU_LIKE_IT_WRAPPED: 'Vill du ha det inslaget?',
  GREETING_INSTRUCTIONS: 'Hälsning till mottagaren (max {0} tecken/rad)',
  GIFT_WRAP_DESCRIPTION:
    'Varje bok läggs i en egen presentförpackning (se bilden ovan) och skickas tillsammans med övriga böcker i din beställning. Din hälsning skrivs ut på förpackningen.',
  EDIT_GIFT_WRAPPING_FOR: 'Ändra presentinslagning för',
  REQUIRE_AT_LEAST_ONE_ROW: 'Fyll i minst 1 av raderna.',
  GIFT_WRAP: 'Presentinslagning',
  LINE: 'Rad',
  SHIPPING_TIME: 'frakttid',

  /* Important changes list */
  CAMPAIGN_CODE_DATE: 'Kampanjkodens giltighetstid har löpt ut.',
  CAMPAIGN_CODE_INVALID: 'Kampanjkodens kriterier är ej uppfyllda.',
  CAMPAIGN_CODE_ALREADY_USED: 'Kampanjkoden är redan använd.',
  CAMPAIGN_CODE_NOT_ENOUGH_ARTICLES: 'Kampanjkoden kräver fler med artiklar.',
  CAMPAIGN_CODE_AMOUNT: 'Kampanjkodens minsta artikelvärde är ej uppfyllt.',
  CAMPAIGN_CODE_ARTICLE_MISSING: 'Kampanjkoden saknar obligatorisk artikel.',
  CAMPAIGN_CODE_ARTICLES_MISSING: 'Kampanjkoden saknar obligatoriska artiklar.',
  GIFT_CERTIFICATE_INVALID:
    'Ett eller flera av presentkorten är inte längre giltiga och har tagits bort från din beställning.',
  STORE_CREDIT_INACTIVE: 'Tillgodot är inte längre giltigt.',
  STORE_CREDIT_LOW_AMOUNT:
    'Tillgodot har inte längre tillräckligt med krediter.',
  PRODUCT_CHANGES_DELIVERY_TIME_CHANGE:
    'Leveranstiden för {0} har ändrats från {1} till {2} dagar.',
  PRODUCT_CHANGES_UNAVAILABLE_PRODUCT:
    '{0} {1} kan inte längre beställas och har tagits bort ur din varukorg.',
  PRODUCT_CHANGES_UNAVAILABLE_PRODUCT_STATUS_ONE: '{0} {1} är tyvärr',
  PRODUCT_CHANGES_UNAVAILABLE_PRODUCT_STATUS_TWO:
    'och har tagits bort ur din varukorg.',

  /* Cart changes list modal */
  IMPORTANT_CHANGES: 'Viktiga händelser',

  /* Cart changes alert modal */
  OOPS: 'Hoppsan!',
  CHANGES_ALERT_PARAGRAPH_1:
    'Vi upptäckte ny information när din beställning skulle genomföras.',
  CHANGES_ALERT_PARAGRAPH_2:
    'Försök igen, men titta först igenom den nya informationen på sidan. ',
  SHOW_CHANGES: 'Visa händelser',

  /* Campaign code */
  INVALID_CAMPAIGN_CODE: 'Ange en korrekt kampanjkod',
  CAMPAIGN_CODE_LIMIT: 'Du kan ange högst 1 kampanjkod per beställning.',
  DELETE_CAMPAIGN_CODE: 'Ta bort kampanjkod "{0}"',

  /* Gift certificate */
  GIFT_CERTIFICATE: 'Presentkort',
  USE_GIFT_CERTIFICATE: 'Använd presentkort',
  SIXTEEN_DIGIT_CODE: '16-siffrig kod',
  GIFT_CERTIFICATE_SHOULD_CONSIST_OF_SIXTEEN_DIGITS:
    'Koden ska bestå av 16 st siffror.',
  INVALID_GIFT_CERTIFICATE:
    'Vi kan inte hitta presentkortet. Kontrollera koden och försök igen.',
  NO_AMOUNT_LEFT_GIFT_CERTIFICATE: 'Ingen användning kvar',

  /* Shipping */
  SHIPPING_OPTION: 'Leveranssätt',
  CHOOSE_AGENT: 'Visa ombud',
  ZIPCODE_INVALID_FORMAT: 'Ange korrekt postnummerformat 5 siffror',
  ZIPCODE_EMPTY: 'Ange ett postnummer',
  NO_ORDER_PARAMETER: 'Order parametern saknas.',
  SHIPPING_CHANGE:
    'Tillgängliga leveranssätt har ändrats, vänligen se över ditt val av leveranssätt.',
  YOUR_SHIPMENT_IS_SENT_IN_X_DAYS:
    'Din beställning skickas från oss inom {0} vardagar.',
  FREIGHT_TIME_ADDED: 'Frakttid tillkommer.',
  AGENT_FOR_ZIP_CODE_X: 'Rekommenderade Schenker-ombud för postnummer {0}:',
  SHIPPMENT_SENT_WITHIN_WEEKDAYS:
    'Din beställning skickas från oss inom {0} vardagar.',
  SHIPPMENT_SENT_IN_WEEKDAYS:
    'Din beställning skickas från oss om {0} vardagar.',
  SHIPPMENT_SENT_IN_STOCK: 'Din beställning skickas från oss {0}.',
  FREIGHT_TIME_ADDED_SELECTED:
    'Frakttid för det leveransalternativ du väljer nedan tillkommer.',

  /* Log in */
  LOGIN: 'Logga in',
  LOGIN_AND_CONTINUE: 'Logga in och fortsätt',
  CANCEL: 'Avbryt',
  LOGOUT: 'Logga ut',
  USERNAME: 'Användarnamn',
  PASSWORD: 'Lösenord',
  SHOW: 'Visa',
  FORGOT_USERNAME_OR_PASSWORD_SHORT: 'Glömt användarnamn eller lösenord?',
  FORGOT_USERNAME_OR_PASSWORD_LONG:
    'Har du glömt ditt användarnamn och/eller lösenord?',
  INVALID_CREDENTIALS: 'Användarnamnet och lösenordet matchar inte.',
  LOGGED_IN_AS: 'Inloggad som',
  HOURS_X: 'Öppettider: {0}',
  SUPPLY_USERNAME: 'Ange ditt användarnamn',
  SUPPLY_PASSWORD: 'Ange ditt lösenord',

  /* Personal number */
  PLUS_PRICE_SUBMIT_PERSONAL_NUMBER:
    'Komplettera ditt konto med personnummer för att aktivera Pluspris-erbjudanden i varukorgen.',
  TYPE_PERSONAL_NUMBER: 'Ange personnummer',
  PERSONAL_NUMBER_PLACEHOLDER: 'ÅÅMMDDXXXX',
  TYPE_PERSONAL_NUMBER_FORMAT:
    'Ange personnumret i formatet ååmmddxxxx (glöm inte de 4 sista).',
  INVALID_CIVIC_NUMBER: 'Ange ett korrekt personnummer',

  /* Navbar */
  CREATE_ACCOUNT: 'Skapa konto',
  FOR_INDIVIDUAL: 'För privatperson',
  FOR_COMPANY_ORGANISATION: 'För företag/organisation',

  /* Options */
  YES_NEWSLETTER: 'Jag vill ha erbjudanden, nyheter & boktips från Bokus.',
  YES_STUDENT_AND_STUDENT_OFFERS:
    'Jag är student och vill ha studenterbjudanden',
  YES_THANK_YOU_EXCLAMATION_MARK: 'Ja tack!',
  SEND_NEWSLETTER: 'skicka mig erbjudanden, boktips och information!',
  YES_STUDENT_OFFERS: 'Jag vill ha studenterbjudanden',
  YES_THANK_YOU: 'Ja tack,',
  NEWSLETTER_INFO:
    'Väljer du att bocka i rutan samtycker du till att Bokus skickar dig erbjudanden, boktips och information. Vanligen sker detta via e-post, men även sms och postala utskick kan förekomma. Du kan när som helst avsäga dig dessa utskick via utskicket, via Mina Sidor eller genom att kontakta vår kundservice. Om du vill ha studentspecifika erbjudanden och information, ange det nedan.',

  /* KCO */
  PURCHASE: 'Slutför köp',
  KLARNA_CHECKOUT_MESSAGES_LIST_TITLE:
    'För många adresstecken. Korta ner adressinformationen och försök igen.',
  KLARNA_CHECKOUT_COUNTRY_CHANGE_INFO:
    'Priser, fraktavgift och leveranssätt har anpassats för ditt valda leveransland.',

  /* General */
  REMOVE: 'Ta bort',
  USE: 'Använd',
  YES: 'Ja',
  NO: 'Nej',
  LOADING: 'Laddar',
  CLOSE: 'Stäng',
  SELECT: 'Välj',
  CHANGE: 'Ändra',
  CONTINUE: 'Fortsätt',
  SAVE: 'Spara',
  BACK_TO_STORE: 'Tillbaka till butiken',
  BY: 'av',
  IS: 'är',
  OK: 'Ok',

  /* Shipping */
  LEFT_TO_FREE_SHIPPING: 'Du har {0} kvar till gratis leveransalternativ.',
  FREE_FREIGHT_AVAILABLE: 'Gratis leveransalternativ finns tillgängligt.',
  DELIVERY_TIME: 'Leveranstid:',
  DELIVERY: 'Leverans',
  FREIGHT_TIME: 'Frakttid:',
  FREIGHT_TIME_UP_TO: 'Frakttid: upp till',
  WEEKDAYS: 'vardagar',
  SEE_ABOVE: 'se ovan',
  SHIPPED_WITHIN: 'Skickas inom',
  SHIPPED_IN: 'Skickas om',
  LARGE_DELIVERY: 'Större paket hämtas hos',
  LARGE_DELIVERY_AGENT: 'ditt närmaste Schenker-ombud.',
  PPD_DELIVERY: 'Små paket får hängas i påse på dörren',
  FREE_SHIPPING_INFO:
    'Små paket delas ut av antingen CityMail eller PostNord. Om paket som levereras med CityMail inte går ner i brevlådan (och ingen är hemma) så kommer paketet att lämnas utanför din dörr.',
  SELECT_AGENT: 'Välj ombud',
  CHANGE_AGENT: 'Byt ombud',
  REMOVE_AGENT: 'Ta bort ombudsval',
  OPENING_HOURS: 'Öppettider',
  SELECT_ZIPCODE: 'Ange postnummer',
  SELECT_ZIPCODE_DESC:
    'Ange ditt postnummer för att se Schenker-ombud nära dig.',
  FIVE_DIGIT_ZIP_CODE: '5-siffrigt postnummer',
  ZIPCODE_INVALID_MESSAGE: 'Postnumret är inte korrekt',
  MISSING_AGENT_TITLE: 'Ombud saknas',
  MISSING_AGENT_DESC:
    'Det finns tyvärr inget rekommenderat Schenker-ombud för postnummer {0}. Vid leverans till postnummer {0} kommer större paket att hämtas ut hos ditt närmaste Posten utlämningsställe.',
  SAVE_AGENT: 'Spara ombudsval',
  SELECT_AGENT_CONTINUE: 'Spara ombudsval',
  FREE_FREIGHT_PLAY_CUSTOMER:
    'Alltid gratis leveransalternativ med Bokus Play!',
  /* Error */
  UNKNOWN_ERROR: 'Ett okänt fel uppstod',
  UNKNOWN_ERROR_RELOAD: 'Ett okänt fel uppstod. Vänligen ladda om sidan.',
  UNKNOWN_ERROR_CONFIRMATION_PAGE:
    'Tack-sidan kan inte visas på grund av ett okänt fel. Om du inte får en e-postbekräftelse inom 1 timme, vänligen lägg om din beställning.',
  UNHANDLED_EXCEPTION: 'Ett ohanterat fel uppstod',
  DEFAULT_ERROR: 'Ett fel uppstod',
  ERROR_MESSAGE: 'Ett serverfel uppstod',
  ORDER_NOT_FOUND: 'Din order kunde inte hittas',
  INVALID_USER: 'Felaktig användare',
  PAYMENT_PROVIDER: 'Ett fel uppstod hos betalleverantören',
  DEPRECATED: 'Deprecated',
  NOT_FOUND: 'Hittades inte',
  NOT_IMPLEMENTED: 'Denna funktionalitet är inte implementerad',
  NO_SESSION_ID_FOUND: 'Inget sessions-id hittades',
  INVALID_SESSION: 'Sessionens id är felaktigt',

  /* Cart undeliverable page */
  CART_UNDELIVERABLE_TITLE: 'Aj då!',
  CART_UNDELIVERABLE_BODY:
    'Tyvärr har dina varor hunnit sälja slut hos våra leverantörer. Vi är ledsna för det inträffade.',
  SOLD_OUT_DEFINITELY:
    'Boken är definitivt slutsåld hos samtliga våra leverantörer.',
  SOLD_OUT_TEMPORARILY: 'Boken är tillfälligt slut.',

  /* Address alert */
  ADDRESS_ALERT_TITLE: 'För många adresstecken',
  ADDRESS_ALERT_PARAGRAPH_1:
    'Beställningen kunde inte genomföras på grund av att ett eller flera adressfält har för många tecken.',
  ADDRESS_ALERT_PARAGRAPH_2: 'Korta ner adressinformationen och försök igen.',

  /* Address list */
  CORRECT_FOLLOWING: 'Vänligen korrigera följande',

  /* Confirmation info page */
  CONFIRMATION_INFO_TITLE: 'Tack för din beställning!',
  CONFIMRATION_EMAIL_TO_X: 'Vi skickar strax en bekräftelse till {0}',
  ORDER_NUMBER: 'Ordernummer',
  USER_ACCOUNT: 'Användarkonto',
  ENV_PSTN_TITLE: 'Snabb frakt',
  ENV_EKNMI_TITLE: 'Fri frakt',
  ENV_STDRD_TITLE: 'Frakt',
  EKNMI_ENV_STDRD_SMALL_PACKAGES:
    'Små paket levereras till din brevlåda med CityMail alt. med Posten.',
  ENV_PSTN_SMALL_PACKAGES: 'Små paket levereras till din brevlåda med Posten.',
  CHOSEN_DELIVERY_AGENT_LARGE_PACKAGES:
    'Större paket hämtas hos följande Schenkerombud:',
  NO_CHOSEN_DELIVERY_AGENT_LARGE_PACKAGES:
    'Större paket hämtas hos ditt närmaste Schenkerombud alt. Posten utlämningsställe.',
  PAYMENT_METHOD: 'Betalningssätt',
  KLARNA_SEE_DETAILS: 'Klarna (se detaljer nedan)',
  YOUR_INFORMATION: 'Dina uppgifter',
  DELIVERY_ADDRESS: 'Mottagare',
  ORDER_SUPPORT_KLARNA:
    'För frågor om betalning, vänligen vänd dig direkt till vår betalpartner',
  ORDER_SUPPORT_BOKUS: 'För övriga frågor, kontakta ',
  DELIVERY_INFO:
    'Beställningen skickas från oss {0}. Därefter tillkommer frakttid på {1} vardagar.',
  DELIVERY_INFO_IN_DAYS:
    'Beställningen skickas från oss om {0} vardagar. Därefter tillkommer frakttid på {1} vardagar.',
  DELIVERY_INFO_WITHIN_DAYS:
    'Beställningen skickas från oss inom {0} vardagar. Därefter tillkommer frakttid på {1} vardagar.',
  EXPORT_DELIVERY_INFO_START: 'Beställningen skickas när',
  EXPORT_DELIVERY_INFO_MIDDLE: 'samtliga',
  EXPORT_DELIVERY_INFO_END:
    'varor anlänt till vårt lager, normalt inom {0} vardagar. Därefter tillkommer frakttid på {1} vardagar.',
  BUDBEE_DELIVERY_INFO: 'Budbee tar kontakt innan leverans.',
  MY_ORDER: 'Min beställning',
  NOTHING_LEFT_TO_PAY: 'Det finns inget kvar att betala.',
  DELIVERY_INFO_HEADER: 'Hej!',
  DELIVERY_INFO_MESSAGE:
    'Vi flyttar just nu vårt lager för att förbättra leveranserna till dig som kund. Flytten kan innebära längre ledtider under en begränsad period, så vi ber dig hålla koll på leveransinformationen.',

  /* Company user redirect */
  COMPANY_USER_REDIRECT_TITLE: 'Du skickas vidare till kassan för företag',
  COMPANY_USER_REDIRECT_BODY: 'Denna kassa är endast till för privatpersoner.',

  /* xmas stuff */
  XMAS: 'Julklappar?',
  XMAS_SINGULAR: 'Julklapp?',
  XMAS_ALL_DELIVERBLE: 'Beställningen kommer levereras innan julafton.',
  XMAS_ALL_DELIVERBLE_WITH_FAST:
    'Välj ett av de snabbaste leveranssätten för leverans innan julafton.',
  XMAS_SOME_DELIVERBLE_WITH_FAST:
    'Lägg en separat beställning på markerade varor om du vill att de andra varorna ska hinna fram innan julafton.',
  XMAS_NO_DELIVERY:
    'Beställningen hinner tvyärr inte levereras innan julafton.',
  XMAS_LATEST: `Lägg beställningen senast {0} för leverans innan julafton.`,
}

export const XMAS_DELIVERY_MESSAGES = {
  0: translate('UNKNOWN_ERROR'),
  1: translate('XMAS_ALL_DELIVERBLE'),
  2: translate('XMAS_ALL_DELIVERBLE_WITH_FAST'),
  3: translate('XMAS_SOME_DELIVERBLE_WITH_FAST'),
  4: translate('XMAS_NO_DELIVERY'),
}

export const VALIDATION_MESSAGES = {
  0: translate('UNKNOWN_ERROR'),
  1: translate('UNHANDLED_EXCEPTION'),
  2: translate('INVALID_CAMPAIGN_CODE'),
  3: translate('INVALID_CIVIC_NUMBER'),
  4: translate('INVALID_SESSION'),
  5: translate('ZIPCODE_INVALID_MESSAGE'),
  6: translate('INVALID_GIFT_CERTIFICATE'),
  7: translate('ORDER_NOT_FOUND'),
  8: translate('INVALID_USER'),
  9: translate('PAYMENT_PROVIDER'),
  10: translate('DEPRECATED'),
  15: translate('NO_AMOUNT_LEFT_GIFT_CERTIFICATE'),
  403: translate('INVALID_CREDENTIALS'),
  404: translate('NOT_FOUND'),
  501: translate('NOT_IMPLEMENTED'),
  502: translate('UNKNOWN_ERROR_RELOAD'),
}

export function translate(id) {
  return STRINGS.hasOwnProperty(id)
    ? STRINGS[id]
    : `Error: translation missing for id ${id}!`
}

export function composeMessage(id, ...args) {
  return STRINGS.hasOwnProperty(id)
    ? STRINGS[id].replace(/{(\d)}/g, (_, index) => args[Number(index)])
    : `Error: translation missing for id ${id}!`
}

/*
  TODO: Could this be used to construct messages more simple perhaps: https://jaysoo.ca/2014/03/20/i18n-with-es2015-template-literals/
 */
