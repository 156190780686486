import {
  ADD_CAMPAIGN_CODE_START,
  ADD_CAMPAIGN_CODE_FAILURE,
  ADD_CAMPAIGN_CODE_SUCCESS,
  DELETE_CAMPAIGN_CODE_START,
  DELETE_CAMPAIGN_CODE_FAILURE,
  DELETE_CAMPAIGN_CODE_SUCCESS,
} from './constants'

export const addCampaignCodeStart = campaignCode => ({
  type: ADD_CAMPAIGN_CODE_START,
  campaignCode,
})

export const addCampaignCodeSuccess = cart => ({
  type: ADD_CAMPAIGN_CODE_SUCCESS,
  cart,
})

export const addCampaignCodeFailure = error => ({
  type: ADD_CAMPAIGN_CODE_FAILURE,
  error,
})

export const deleteCampaignCodeStart = campaignCodeId => ({
  type: DELETE_CAMPAIGN_CODE_START,
  campaignCodeId,
})

export const deleteCampaignCodeSuccess = cart => ({
  type: DELETE_CAMPAIGN_CODE_SUCCESS,
  cart,
})

export const deleteCampaignCodeFailure = error => ({
  type: DELETE_CAMPAIGN_CODE_FAILURE,
  error,
})
