import React, { PureComponent, Fragment } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import { Helmet } from 'react-helmet-async'

import getEnvConfig from 'utils/getEnvConfig'
import GoogleTagManager from 'containers/GoogleTagManager'
import Loading from 'components/Loading'

import {
  CHECKOUT_PAGE_PATH,
  CHECKOUT_CONFIRMATION_PAGE_PATH,
  CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH,
  LOGIN_PAGE_PATH,
} from './constants'
import './style.scss'
import ErrorBoundary from 'components/ErrorBoundary'

const CheckoutPage = loadable(() => import('containers/Pages/Checkout'), {
  fallback: <Loading spinnerPosition="center" loading={true} />,
})

const CheckoutConfirmationPage = loadable(
  () => import('containers/Pages/Checkout/ConfirmationPage'),
  {
    fallback: <Loading spinnerPosition="center" loading={true} />,
  }
)

const CheckoutCartUndeliverablePage = loadable(
  () => import('containers/Pages/Checkout/CartUndeliverablePage'),
  {
    fallback: <Loading spinnerPosition="center" loading={true} />,
  }
)

const LoginPage = loadable(() => import('containers/Pages/Login'), {
  fallback: <Loading spinnerPosition="center" loading={true} />,
})

export class App extends PureComponent {
  render() {
    const { version } = this.props

    return (
      <Router>
        <Fragment>
          <Helmet>
            <meta
              name="generator"
              content={`Bokus Frontend Checkout ${version} run in ${getEnvConfig(
                'MODE'
              )} mode.`}
            />
          </Helmet>
          <GoogleTagManager gtmId="GTM-TPG9DKL" />
          <ErrorBoundary>
            <Route
              exact
              path={CHECKOUT_PAGE_PATH}
              component={props => <CheckoutPage props={props} />}
            />
            <Route
              exact
              path={CHECKOUT_CONFIRMATION_PAGE_PATH}
              component={props => <CheckoutConfirmationPage props={props} />}
            />
            <Route
              exact
              path={CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH}
              component={props => (
                <CheckoutCartUndeliverablePage props={props} />
              )}
            />
            <Route
              exact
              path={LOGIN_PAGE_PATH}
              component={props => <LoginPage props={props} />}
            />
          </ErrorBoundary>
        </Fragment>
      </Router>
    )
  }
}

export default App
