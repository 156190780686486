import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'

import { GET_CONFIRMATION_CART_START } from './constants'
import {
  getConfirmationCartSuccess,
  getConfirmationCartFailure,
} from './actions'

function* sagaGetConfirmationCartStart(action) {
  const state = yield select()
  try {
    const response = yield call(
      request,
      endpoints.createGetConfirmationCartUrl(
        action.orderId,
        state.session.sessionId
      ),
      'GET'
    )

    yield put(getConfirmationCartSuccess(response))
  } catch (e) {
    yield put(getConfirmationCartFailure(e))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_CONFIRMATION_CART_START, sagaGetConfirmationCartStart),
  ])
}
