const DEFAULT_PAGE_PATH = ''
const CHECKOUT_PAGE_PATH = `${DEFAULT_PAGE_PATH}/checkout`
const CHECKOUT_PAGE_PATH_VARIANT = `${DEFAULT_PAGE_PATH}/checkout/`
const CHECKOUT_CONFIRMATION_PAGE_PATH = `${CHECKOUT_PAGE_PATH}/confirmation`
const CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH = `${CHECKOUT_PAGE_PATH}/basket-undeliverable`
const LOGIN_PAGE_PATH = `${DEFAULT_PAGE_PATH}/login`

const GET_SESSION_ID_FROM_COOKIE = true

const ONE_TRUST_SCRIPT_URL =
  'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
const ONE_TRUST_DOMAIN = '473c6794-b151-43f5-b2e9-ae2e002c6e5e'

const IDLE_MODAL_TIMEOUT_SECONDS = 60 * 30

const config = {
  DEFAULT_PAGE_PATH,
  CHECKOUT_PAGE_PATH,
  CHECKOUT_PAGE_PATH_VARIANT,
  CHECKOUT_CONFIRMATION_PAGE_PATH,
  CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH,
  LOGIN_PAGE_PATH,
  GET_SESSION_ID_FROM_COOKIE,
  ONE_TRUST_SCRIPT_URL,
  ONE_TRUST_DOMAIN,
  IDLE_MODAL_TIMEOUT_SECONDS,
}

export default config
