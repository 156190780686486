import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SHOW_PASSWORD,
  HIDE_PASSWORD,
} from './constants'

const initialState = Immutable({
  error: null,
  loading: false,
  showPwd: false,
})

const Reducer = createReducer(initialState, {
  [LOGIN_START](state) {
    return state.set('loading', true).set('error', null)
  },
  [LOGIN_SUCCESS](state) {
    return state.set('error', null).set('loading', false)
  },
  [LOGIN_FAILURE](state, { error }) {
    return state.set('error', error).set('loading', false)
  },
  [SHOW_PASSWORD](state) {
    return state.set('showPwd', true)
  },
  [HIDE_PASSWORD](state) {
    return state.set('showPwd', false)
  },
})

export default Reducer
