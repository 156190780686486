import { convertUnit } from 'utils/money'
import { convertCustomerIsNewToNumber, createOrderProductArray } from './utils'

export const confirmationEvent = (cart, giftWrapOption) => {
  return {
    event: 'transaction',
    customerIsNew: convertCustomerIsNewToNumber(cart.customer_new),
    basket: {
      totalPrice: convertUnit(cart.amount_to_pay).toString(), // Total sum of product unit price + shipping - discounts
      couponCode: cart.campaign ? cart.campaign : null,
      products: createOrderProductArray(cart),
    },
    transactionId: cart.order_info ? cart.order_info.order_id : null,
    transactionAffiliation: 'Standard Checkout',
    transactionTotal: convertUnit(cart.amount_to_pay_no_tax).toString(), // Exclusive VAT.
    transactionTax: convertUnit(cart.total_tax_amount).toString(),
    transactionShipping: convertUnit(
      cart.freight_total_amount_no_tax
    ).toString(),
    transactionProducts: createGTMTransactionProductArray(cart, giftWrapOption),
    customerIsLoggedIn: cart.order_info.username ? 1 : 0,
    tradedoublerChecksum: cart.order_info.tradedouble_checksum,
    emailHashed: cart.order_info.email_hash,
    voyadoCartRef: cart.voyado_cart_ref,
  }
}

export const createGTMTransactionProductArray = (cart, giftWrapOption) => {
  let gtmProductArray = []
  if (cart.products) {
    gtmProductArray = cart.products.reduce((accumulator, currentValue) => {
      accumulator.push({
        sku: currentValue.sku,
        name: currentValue.title,
        category: currentValue.category,
        price: convertUnit(currentValue.price_no_tax).toString(), // Unit price what the customer will pay.
        quantity: currentValue.quantity,
      })
      // Check if product is giftwrapped, if so add data to the result.
      if (giftWrapOption && !!currentValue.gw) {
        accumulator.push({
          sku: currentValue.sku + '_GIFTWRAP',
          name: 'Paketinslagning',
          category: 'Service',
          price: convertUnit(
            giftWrapOption.price - giftWrapOption.vat
          ).toString(),
          quantity: currentValue.quantity,
        })
      }

      return accumulator
    }, [])
  }
  return gtmProductArray
}
