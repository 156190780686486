export const GET_CHECKOUT_SNIPPET_START = 'GET_CHECKOUT_SNIPPET_START'
export const GET_CHECKOUT_SNIPPET_SUCCESS = 'GET_CHECKOUT_SNIPPET_SUCCESS'
export const GET_CHECKOUT_SNIPPET_FAILURE = 'GET_CHECKOUT_SNIPPET_FAILURE'
export const KCO_SCRIPT_EXECUTION_ERROR = 'KCO_SCRIPT_EXECUTION_ERROR'
export const SHIPPING_ADDRESS_CHANGE_START = 'SHIPPING_ADDRESS_CHANGE_START'
export const KCO_SUSPEND = 'KCO_SUSPEND'
export const KCO_RESUME = 'KCO_RESUME'
export const ADD_SUSPEND_ACTION = 'ADD_SUSPEND_ACTION'
export const DELETE_SUSPEND_ACTION = 'DELETE_SUSPEND_ACTION'
export const SET_DELIVERY_ADDRESS_COUNTRY_ISO_START =
  'SET_DELIVERY_ADDRESS_COUNTRY_ISO_START'
export const SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS =
  'SET_DELIVERY_ADDRESS_COUNTRY_ISO_SUCCESS'
export const SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE =
  'SET_DELIVERY_ADDRESS_COUNTRY_ISO_FAILURE'
export const UPDATE_SHIPPING_OPTION_SELECTED = 'UPDATE_SHIPPING_OPTION_SELECTED'
