import { generateUuId } from './uuid'

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json().then(json => {
      const message = json.message ? json.message : response.statusText
      let error = new Error(message)
      error.response = response
      error.json = json
      throw error
    })
  }

  const error = new Error(response.statusText)
  error.response = response
  throw error
}

function parseJSON(response) {
  const contentType = response.headers.get('content-type')

  // To check that response not is empty.
  // Because sometimes we get an empty response from backend even when it's a correct request.
  // An error whould occurr when resonse.json() is empty.
  // Maybe this check could be removed if backend always return json?
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json()
  }

  return null
}

function request(url, method = 'GET', data = null, signal = null) {
  let options = {
    method: method,
    mode: 'cors',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Bokus-Correlation-Id': generateUuId(),
    },
    signal: signal,
  }

  if (data) {
    options.body = JSON.stringify(data)
  }

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}

export { request }
