import dev from 'env/dev'
import local from 'env/local'
import prod from 'env/prod'
import test from 'env/test'
import _get from 'lodash/get'

const environments = { dev, local, prod, test }

const getEnvConfig = key => {
  const currentEnv = _get(window, 'SERVER_DATA.env', 'local')
  const currentConfig = _get(environments[currentEnv], key)

  if (currentConfig !== undefined) {
    return currentConfig
  }

  console.warn('No environment configuration found')
  return null
}

export default getEnvConfig
