import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'
import { getResetOrderStart } from 'containers/Pages/Checkout/ResetOrder/actions'

import { hideMenuNoAnimation } from '../GUI/actions'

import { LOGIN_START } from './constants'
import { loginSuccess, loginFailure, hidePassword } from './actions'

function* sagaLoginStart(action) {
  const state = yield select()

  try {
    const response = yield call(
      request,
      endpoints.createLoginUrl(state.session.sessionId),
      'POST',
      { username: action.username, password: action.password }
    )

    yield put(hideMenuNoAnimation())

    if (state.login.showPwd) {
      yield put(hidePassword())
    }

    yield put(loginSuccess(response))
    yield put(getResetOrderStart())
  } catch (e) {
    yield put(loginFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(LOGIN_START, sagaLoginStart)])
}
