import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  SHOW_MENU,
  HIDE_MENU_WITH_ANIMATION,
  HIDE_MENU_NO_ANIMATION,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_POPOVER,
  HIDE_POPOVER,
} from './constants'

const initialState = Immutable({
  expandedMenu: false,
  menuAnimation: true,
  modal: '',
  popover: '',
})

const Reducer = createReducer(initialState, {
  [SHOW_MENU](state) {
    return state.set('expandedMenu', true).set('menuAnimation', true)
  },
  [HIDE_MENU_WITH_ANIMATION](state) {
    return state.set('expandedMenu', false).set('menuAnimation', true)
  },
  [HIDE_MENU_NO_ANIMATION](state) {
    return state.set('expandedMenu', false).set('menuAnimation', false)
  },
  [SHOW_MODAL](state, { modal }) {
    return state.set('modal', modal)
  },
  [HIDE_MODAL](state) {
    return state.set('modal', '')
  },
  [SHOW_POPOVER](state, { popover }) {
    return state.set('popover', popover)
  },
  [HIDE_POPOVER](state) {
    return state.set('popover', '')
  },
})

export default Reducer
