import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import { VALIDATION_MODAL_SHOWN } from './constants'

const initialState = Immutable({
  validationModalShown: false,
})

const Reducer = createReducer(initialState, {
  [VALIDATION_MODAL_SHOWN](state) {
    return state.set('validationModalShown', true)
  },
})

export default Reducer
