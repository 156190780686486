import {
  SHOW_PERSONAL_NUMBER_FORM,
  UPDATE_PERSONAL_NUMBER,
  ADD_PERSONAL_NUMBER_START,
  ADD_PERSONAL_NUMBER_FAILURE,
  ADD_PERSONAL_NUMBER_SUCCESS,
} from './constants'

export const showPersonalNumberForm = show => ({
  type: SHOW_PERSONAL_NUMBER_FORM,
  show,
})

export const updatePersonalNumber = personalNumber => ({
  type: UPDATE_PERSONAL_NUMBER,
  personalNumber,
})

export const addPersonalNumberStart = personalNumber => ({
  type: ADD_PERSONAL_NUMBER_START,
  personalNumber,
})

export const addPersonalNumberSuccess = () => ({
  type: ADD_PERSONAL_NUMBER_SUCCESS,
})

export const addPersonalNumberFailure = error => ({
  type: ADD_PERSONAL_NUMBER_FAILURE,
  error,
})
