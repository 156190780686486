import 'react-app-polyfill/ie9'
import 'core-js/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import App from './components/App'
import store from './redux/store'
import './style.scss'

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <App version={process.env.REACT_APP_VERSION} />
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
)
