import {
  SET_NEWSLETTER_START,
  SET_NEWSLETTER_SUCCESS,
  SET_NEWSLETTER_FAILURE,
  SET_NEWSLETTER_DEFAULT,
} from './constants'

export const setNewsletterStart = checked => ({
  type: SET_NEWSLETTER_START,
  checked,
})

export const setNewsletterSuccess = checked => ({
  type: SET_NEWSLETTER_SUCCESS,
  checked,
})

export const setNewsletterFailure = error => ({
  type: SET_NEWSLETTER_FAILURE,
  error,
})

export const setNewsletterDefault = checked => ({
  type: SET_NEWSLETTER_DEFAULT,
  checked,
})
