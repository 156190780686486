import {
  GET_CONFIRMATION_CART_START,
  GET_CONFIRMATION_CART_SUCCESS,
  GET_CONFIRMATION_CART_FAILURE,
} from './constants'

export const getConfirmationCartStart = orderId => ({
  type: GET_CONFIRMATION_CART_START,
  orderId,
})

export const getConfirmationCartSuccess = cart => ({
  type: GET_CONFIRMATION_CART_SUCCESS,
  cart,
})

export const getConfirmationCartFailure = error => ({
  type: GET_CONFIRMATION_CART_FAILURE,
  error,
})
