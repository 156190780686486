import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  SET_NEWSLETTER_START,
  SET_NEWSLETTER_SUCCESS,
  SET_NEWSLETTER_FAILURE,
} from './constants'
import { GET_CHECKOUT_SNIPPET_SUCCESS } from '../KlarnaCheckout/constants'
import { GET_RESET_ORDER_SUCCESS } from '../ResetOrder/constants'

const initialState = Immutable({
  error: null,
  loading: false,
  checked: false,
  previous_checked: false,
})

const Reducer = createReducer(initialState, {
  [SET_NEWSLETTER_START](state, { checked }) {
    return state.set('loading', true).set('checked', checked)
  },
  [SET_NEWSLETTER_SUCCESS](state, { checked }) {
    return state
      .set('error', null)
      .set('loading', false)
      .set('previous_checked', checked)
  },
  [SET_NEWSLETTER_FAILURE](state, { error }) {
    return state
      .set('error', error)
      .set('loading', false)
      .set('checked', state.previous_checked)
  },
  [GET_CHECKOUT_SNIPPET_SUCCESS](state, { cart }) {
    return state
      .set('checked', cart.newsletter)
      .set('previous_checked', cart.newsletter)
  },
  [GET_RESET_ORDER_SUCCESS](state, { cart }) {
    return state
      .set('checked', cart.newsletter)
      .set('previous_checked', cart.newsletter)
  },
})

export default Reducer
