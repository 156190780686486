import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import { LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAILURE } from './constants'

const initialState = Immutable({
  user: null,
  error: null,
  loading: false,
})

const Reducer = createReducer(initialState, {
  [LOGOUT_START](state) {
    return state.set('loading', true)
  },
  [LOGOUT_SUCCESS](state) {
    return state.set('error', null).set('loading', false)
  },
  [LOGOUT_FAILURE](state, { error }) {
    return state.set('error', error).set('loading', false)
  },
})

export default Reducer
