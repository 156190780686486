export const GIFT_CERTIFICATE_TOGGLE = 'GIFT_CERTIFICATE_TOGGLE'
export const UPDATE_GIFT_CERTIFICATE = 'UPDATE_GIFT_CERTIFICATE'
export const ADD_GIFT_CERTIFICATE_START = 'ADD_GIFT_CERTIFICATE_START'
export const ADD_GIFT_CERTIFICATE_SUCCESS = 'ADD_GIFT_CERTIFICATE_SUCCESS'
export const ADD_GIFT_CERTIFICATE_FAILURE = 'ADD_GIFT_CERTIFICATE_FAILURE'
export const GIFT_CERTIFICATE_CLEAR_ERROR = 'GIFT_CERTIFICATE_CLEAR_ERROR'
export const GIFT_CERTIFICATE_SET_VALIDATED = 'GIFT_CERTIFICATE_SET_VALIDATED'
export const DELETE_GIFT_CERTIFICATE_START = 'DELETE_GIFT_CERTIFICATE_START'
export const DELETE_GIFT_CERTIFICATE_SUCCESS = 'DELETE_GIFT_CERTIFICATE_SUCCESS'
export const DELETE_GIFT_CERTIFICATE_CODE_FAILURE =
  'DELETE_GIFT_CERTIFICATE_FAILURE'
