export const VALIDATION_MODAL_SHOWN = 'VALIDATION_MODAL_SHOWN'

export const CUSTOMER_TYPES = {
  STUDENT: 'STUDENT',
  PRIVAT: 'PRIVAT',
  KOMMUN: 'KOMMUN',
  STAT: 'STAT',
  KANDSTING: 'KANDSTING',
  FORETAG: 'FORETAG',
}
