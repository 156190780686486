import max from 'lodash/max'
import min from 'lodash/min'

import {
  CHECKOUT_PAGE_PATH,
  CHECKOUT_CONFIRMATION_PAGE_PATH,
  CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH,
  LOGIN_PAGE_PATH,
} from 'components/App/constants'

export function getParameter(name) {
  let result = null
  let tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=')

      if (tmp[0] === name) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

export function getDestination() {
  let destination = getParameter('destination')
  destination = destination ? destination : '/'

  return destination
}

export function isReactDestination(destination) {
  return (
    destination === CHECKOUT_PAGE_PATH ||
    destination === CHECKOUT_CONFIRMATION_PAGE_PATH ||
    destination === CHECKOUT_CART_UNDELIVERABLE_PAGE_PATH ||
    destination === LOGIN_PAGE_PATH
  )
}

/**
 * Goes through the Klarna Checkout HTML snippet and re-adds the script tags.
 * This is necessary otherwise the scripts tags are not going to be evaluated.
 * Somewhat hacky :-(
 * https://developers.klarna.com/en/:country/kco-v3/checkout/2-render-the-checkout/
 * @param {Element} element
 */
export function makeSureScriptsAreExecuted(element) {
  let scriptsTags = element.getElementsByTagName('script')

  for (let i = 0; i < scriptsTags.length; i++) {
    try {
      let parentNode = scriptsTags[i].parentNode
      let newScriptTag = document.createElement('script')
      newScriptTag.type = 'text/javascript'
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    } catch (e) {
      return { success: false, error: e }
    }
  }

  return { success: true, error: null }
}

/*
 * Fast cloning with data loss - JSON.parse/stringify
 * If you do not use Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types within your object
 * https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
 */
export function deepClone(object) {
  return JSON.parse(JSON.stringify(object))
}

export function createArray(num, whatTo) {
  var arr = []
  for (var i = 0; i < num; i++) {
    arr.push(whatTo)
  }
  return arr
}

export function calculateMaxDifference(array) {
  const maxValue = max(array)
  const minValue = min(array)
  return maxValue - minValue
}
