import React, { PureComponent } from 'react'
import Error from 'components/Error'

export class ErrorBoundary extends PureComponent {
  state = { error: null, errorInfo: null }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      return <Error error={this.state.error} errorInfo={this.state.errorInfo} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
