import {
  GET_GIFT_WRAP_FAILURE,
  GET_GIFT_WRAP_START,
  GET_GIFT_WRAP_SUCCESS,
  SAVE_GIFT_WRAP_FAILURE,
  SAVE_GIFT_WRAP_START,
  SAVE_GIFT_WRAP_SUCCESS,
  SELECT_PRODUCT_TO_WRAP,
} from './constants'

export const getGiftWrapStart = () => ({
  type: GET_GIFT_WRAP_START,
})

export const getGiftWrapSuccess = response => {
  return {
    type: GET_GIFT_WRAP_SUCCESS,
    giftWrap: response,
  }
}

export const getGiftWrapFailure = error => ({
  type: GET_GIFT_WRAP_FAILURE,
  error,
})

export const selectProductToWrap = productId => ({
  type: SELECT_PRODUCT_TO_WRAP,
  productId: productId,
})

export const saveGiftWrapStart = productsToGiftWrap => ({
  type: SAVE_GIFT_WRAP_START,
  productsToGiftWrap,
})

export const saveGiftWrapSuccess = response => {
  return {
    type: SAVE_GIFT_WRAP_SUCCESS,
    response,
  }
}

export const saveGiftWrapFailure = error => ({
  type: SAVE_GIFT_WRAP_FAILURE,
  error,
})
