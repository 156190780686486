import { convertUnit } from 'utils/money'

export const PRICE_TYPES = {
  DEFAULT: 'Default',
  BOKUS_PLAY: 'Bokus Play',
  CAMPAIGN: 'Campaign',
  BOKUS_PLUS: 'Bokus Plus',
}

export const ITEM_STATUS = {
  IN_STOCK: 'InStock',
  OUT_OF_STOCK: 'OutOfStock',
  NOT_YET_PUBLISHED: 'NotYetPublished',
  DISCONTINUED: 'Discontinued',
  PREORDER: 'PreOrder',
  UNKNOWN: 'Unknown',
}

export const createOrderProductArray = cart => {
  let gtmProductArray = []
  if (cart.products) {
    gtmProductArray = cart.products.map(item => {
      return {
        id: item.sku,
        name: item.title,
        price: convertUnit(item.price).toString(), // Styckpriset
        quantity: item.quantity,
      }
    })
  }
  return gtmProductArray
}

export const calculatePriceType = product => {
  if (product.discount_type === 'PLAY') {
    return PRICE_TYPES.BOKUS_PLAY
  } else if (product.discount_type === 'MEDMERA') {
    return PRICE_TYPES.BOKUS_PLUS
  } else if (
    !product.discount_type &&
    product.total_price !== product.original_total_price
  ) {
    return PRICE_TYPES.CAMPAIGN
  } else {
    return PRICE_TYPES.DEFAULT
  }
}

export const calculateStatus = product => {
  return product.extra.campaign_tags &&
    product.extra.campaign_tags.indexOf('FORHANDSBOKA') > -1
    ? ITEM_STATUS.PREORDER
    : ITEM_STATUS.IN_STOCK
}

export const createBasketWithItems = cart => {
  let items = []
  if (cart.products) {
    items = createItems(cart.products)
   
  }
  return items
}

export const createItems = (products) => {
   return products.map(item => {
      return {
        item_id: item.sku,
        item_name: item.title,
        price: convertUnit(item.price),
        quantity: item.quantity,
        item_price_type: calculatePriceType(item),
        item_status: calculateStatus(item),
        item_delivery: item.max_processing_time,
      }
    })
}

export const createQuantityItems = (products, prevProducts) => {
  return products.map(item => {
     const prevProduct = prevProducts.find((element) => element.product_id === item.product_id && element.quantity > item.quantity)
     return {
       item_id: item.sku,
       item_name: item.title,
       price: convertUnit(item.price),
       quantity: prevProduct.quantity - item.quantity,
       item_price_type: calculatePriceType(item),
       item_status: calculateStatus(item),
       item_delivery: item.max_processing_time,
     }
   })
}

export const convertCustomerIsNewToNumber = customerIsNew => {
  return customerIsNew ? 1 : 0
}

export const calculateTotalPriceBeforeDiscounts = products => {
  return products
    .map(item => item.total_price)
    .reduce((prev, next) => prev + next, 0)
}

export const getCampaignCodeData = cart => {
  const result = cart.discounts.filter(
    discount => discount.type === 'discount_fix_code'
  )
  return result.length > 0 ? result[0] : null
}

export const calculateItemsDeliveryTimeSpan = products => {
  const values = products.map(product => product.max_processing_time)
  const highest = Math.max(...values)
  const lowest = Math.min(...values)
  return highest - lowest
}

export const removedProducts = (prevProducts, products) => {
    const removedItems = prevProducts.filter((element) => {
      return !productsExistsInCart(products, element)
    })
    return removedItems
}

const productsExistsInCart = (products, element) => {
  return products.some((item) => item.product_id === element.product_id)
}

export const decreasedProducts = (prevProducts, products) => {
  const removedItems = products.filter((element) => {
    return prevProducts.some((item) => item.product_id === element.product_id && item.quantity > element.quantity)
  })
  return removedItems
}

export const valueOfRemovedItems = (products) => {
  let totalPrice = 0
  for(let item of products){
    totalPrice += item.price * item.quantity
  }
  return totalPrice
}

export const valueOfDecreaseItems = (removedProducts, products) => {
  if(removedProducts.length > 0) {
    const removedProduct = removedProducts[0]
    
    let totalPrice = 0
    for(let item of products){
      if(item.product_id === removedProduct.product_id) {
        let removedQuanity = item.quantity
        let currentQuantity = removedProduct.quantity
        let quantity = removedQuanity - currentQuantity
        totalPrice += quantity * item.price
      }
    }
    return totalPrice
  }
}