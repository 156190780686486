import { convertUnit } from 'utils/money'
import {
  createQuantityItems,
  decreasedProducts,
  valueOfDecreaseItems

} from './utils'

export const decreaseBasketEvent = (prevCart, cart) => {
  const products = decreasedProducts(prevCart.products, cart.products)
  const items = createQuantityItems(products, prevCart.products)
  const value = convertUnit(valueOfDecreaseItems(products, prevCart.products))

  let result = {
      event: 'remove_from_cart',
      value: value,
      items: items,
  }
  return result
}