import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InfoInverseIcon extends Component {
  static propTypes = {
    position: PropTypes.string,
    type: PropTypes.oneOf(['dark', 'error', 'info', 'light']),
    className: PropTypes.string,
  }

  static defaultProps = {
    type: 'light',
  }

  render() {
    const { type, className } = this.props

    const colors = {
      dark: '#5e5e5e',
      error: '#FF001A',
      info: '#214C62',
      light: '#555555',
      warning: '#F2BF0A',
    }

    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M8.16669 4.83332H9.83335V6.49999H8.16669V4.83332ZM8.16669 8.16666H9.83335V13.1667H8.16669V8.16666ZM9.00002 0.666656C4.40002 0.666656 0.666687 4.39999 0.666687 8.99999C0.666687 13.6 4.40002 17.3333 9.00002 17.3333C13.6 17.3333 17.3334 13.6 17.3334 8.99999C17.3334 4.39999 13.6 0.666656 9.00002 0.666656ZM9.00002 15.6667C5.32502 15.6667 2.33335 12.675 2.33335 8.99999C2.33335 5.32499 5.32502 2.33332 9.00002 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 12.675 12.675 15.6667 9.00002 15.6667Z"
          fill={colors[type]}
        />
      </svg>
    )
  }
}
