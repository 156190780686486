import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SHOW_PASSWORD,
  HIDE_PASSWORD,
} from './constants'

export const loginStart = (username, password) => ({
  type: LOGIN_START,
  username,
  password,
})

export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
  user,
})

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  error,
})

export const showPassword = () => ({
  type: SHOW_PASSWORD,
})

export const hidePassword = () => ({
  type: HIDE_PASSWORD,
})
