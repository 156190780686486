import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  GET_SESSION_ID_FAILURE,
  GET_SESSION_ID_START,
  GET_SESSION_ID_SUCCESS,
} from './constants'

const initialState = Immutable({
  loading: false,
  error: null,
  sessionId: null,
})

const Reducer = createReducer(initialState, {
  [GET_SESSION_ID_START](state) {
    return state
      .set('loading', true)
      .set('sessionId', null)
      .set('error', null)
  },
  [GET_SESSION_ID_SUCCESS](state, { sessionId }) {
    return state
      .set('loading', false)
      .set('sessionId', sessionId)
      .set('error', null)
  },
  [GET_SESSION_ID_FAILURE](state, { error }) {
    return state
      .set('loading', false)
      .set('sessionId', null)
      .set('error', error)
  },
})

export default Reducer
