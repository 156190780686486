import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'

import { SET_NEWSLETTER_START } from './constants'
import { setNewsletterSuccess, setNewsletterFailure } from './actions'

function* sagaSetNewsletterStart(action) {
  const state = yield select()

  try {
    yield call(
      request,
      endpoints.createSetNewsletterUrl(state.session.sessionId),
      'PUT',
      { newsletter: action.checked }
    )

    yield put(setNewsletterSuccess(action.checked))
  } catch (e) {
    yield put(setNewsletterFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(SET_NEWSLETTER_START, sagaSetNewsletterStart)])
}
