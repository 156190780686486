import defaultConfig from './default'

const MODE = 'prod'

const COMPLEX_CHECKOUT_PATH = 'https://www.bokus.com/cgi-bin/cart_send_in.cgi'
const FORGOT_PASSWORD_PAGE_PATH =
  'https://www.bokus.com/cgi-bin/remind_customer.cgi'
const START_PAGE = 'https://www.bokus.com/'

const BASE_API_URL = 'https://www.bokus.com/api/v1'

const config = {
  MODE,
  COMPLEX_CHECKOUT_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  START_PAGE,
  BASE_API_URL,
}

export default {
  ...defaultConfig,
  ...config,
}
