import { all, fork } from 'redux-saga/effects'
import SessionSaga from 'containers/Session/saga'
import AuthSaga from 'containers/Auth/saga'
import CheckoutPageSaga from 'containers/Pages/Checkout/saga'
import CampaignCodeSaga from 'containers/Pages/Checkout/Cart/CampaignCode/saga'
import CompanyRedirect from 'containers/Pages/Checkout/CompanyRedirect/saga'
import GiftCertificateSaga from 'containers/Pages/Checkout/Cart/GiftCertificate/saga'
import KlarnaCheckoutSaga from 'containers/Pages/Checkout/KlarnaCheckout/saga'
import ConfirmationCartSaga from 'containers/Pages/Checkout/ConfirmationPage/ConfirmationCart/saga'
import LoginSaga from 'containers/Login/saga'
import LogoutSaga from 'containers/Login/Logout/saga'
import CartSaga from 'containers/Pages/Checkout/Cart/saga'
import PersonalNumberSaga from 'containers/Pages/Checkout/PersonalNumber/saga'
import NewsletterSaga from 'containers/Pages/Checkout/Newsletter/saga'
import StudentSaga from 'containers/Pages/Checkout/Student/saga'
import ResetOrderSaga from 'containers/Pages/Checkout/ResetOrder/saga'
import WarningStickySaga from 'containers/WarningSticky/saga'
import StartPageRedirectSaga from 'containers/Pages/Checkout/StartPageRedirect/saga'
import GiftWrapSaga from 'containers/Pages/Checkout/GiftWrap/saga'
import GiftWrapOptionsSaga from 'containers/Pages/Checkout/GiftWrapOptions/saga'

function* rootSaga() {
  yield all([
    fork(SessionSaga),
    fork(StartPageRedirectSaga),
    fork(AuthSaga),
    fork(CampaignCodeSaga),
    fork(CartSaga),
    fork(CheckoutPageSaga),
    fork(CompanyRedirect),
    fork(GiftCertificateSaga),
    fork(KlarnaCheckoutSaga),
    fork(ConfirmationCartSaga),
    fork(LoginSaga),
    fork(LogoutSaga),
    fork(PersonalNumberSaga),
    fork(NewsletterSaga),
    fork(StudentSaga),
    fork(ResetOrderSaga),
    fork(WarningStickySaga),
    fork(GiftWrapSaga),
    fork(GiftWrapOptionsSaga),
  ])
}

export default rootSaga
