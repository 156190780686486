import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'
import {
  GET_GIFT_WRAP_FAILURE,
  GET_GIFT_WRAP_START,
  GET_GIFT_WRAP_SUCCESS,
  SELECT_PRODUCT_TO_WRAP,
  SAVE_GIFT_WRAP_START,
  SAVE_GIFT_WRAP_SUCCESS,
  SAVE_GIFT_WRAP_FAILURE,
} from './constants'

const initialState = Immutable({
  ui: {
    loading: false,
    error: null,
    selectedOption: null,
    selectedProductId: null,
  },
  options: null,
})

const Reducer = createReducer(initialState, {
  [GET_GIFT_WRAP_START](state) {
    return state.setIn(['ui', 'loading'], true).setIn(['ui', 'error'], null)
  },
  [GET_GIFT_WRAP_SUCCESS](state, { giftWrap }) {
    // Set first gift wrap option as selected. Add functionality for
    // selecting different options later
    state =
      giftWrap.length > 0
        ? state.setIn(['ui', 'selectedOption'], 0)
        : state.setIn(['ui', 'selectedOption'], null)
    return state
      .set('options', giftWrap)
      .setIn(['ui', 'loading'], false)
      .setIn(['ui', 'error'], null)
  },
  [GET_GIFT_WRAP_FAILURE](state, { error }) {
    return state.setIn(['ui', 'loading'], false).setIn(['ui', 'error'], error)
  },
  [SELECT_PRODUCT_TO_WRAP](state, { productId }) {
    return state.setIn(['ui', 'selectedProductId'], productId)
  },
  [SAVE_GIFT_WRAP_START](state) {
    return state.setIn(['ui', 'loading'], true).setIn(['ui', 'error'], null)
  },
  [SAVE_GIFT_WRAP_SUCCESS](state) {
    return state.setIn(['ui', 'loading'], false).setIn(['ui', 'error'], null)
  },
  [SAVE_GIFT_WRAP_FAILURE](state, { error }) {
    return state.setIn(['ui', 'loading'], false).setIn(['ui', 'error'], error)
  },
})

export default Reducer
