export const GET_CART_START = 'GET_CART_START'
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS'
export const GET_CART_FAILURE = 'GET_CART_FAILURE'
export const REFRESH_SHIPPING_START = 'REFRESH_SHIPPING_START'
export const REFRESH_SHIPPING_SUCCESS = 'REFRESH_SHIPPING_SUCCESS'
export const REFRESH_SHIPPING_FAILURE = 'REFRESH_SHIPPING_FAILURE'
export const SHOW_REMOVE_CART_ITEM_DIALOG = 'SHOW_REMOVE_CART_ITEM_DIALOG'
export const HIDE_REMOVE_CART_ITEM_DIALOG = 'HIDE_REMOVE_CART_ITEM_DIALOG'
export const UPDATE_CART_ITEM_WATCHER = 'UPDATE_CART_ITEM_WATCHER'
export const UPDATE_CART_ITEM_START = 'UPDATE_CART_ITEM_START'
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS'
export const UPDATE_CART_ITEM_FAILURE = 'UPDATE_CART_ITEM_FAILURE'
