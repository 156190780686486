import defaultConfig from './default'

const MODE = 'test'

const COMPLEX_CHECKOUT_PATH = 'https://test.bokus.com/cgi-bin/cart_send_in.cgi'
const FORGOT_PASSWORD_PAGE_PATH =
  'https://test.bokus.com/cgi-bin/remind_customer.cgi'
const START_PAGE = 'https://test.bokus.com/'

const BASE_API_URL = 'https://test.bokus.com/api/v1'

const ONE_TRUST_DOMAIN = `${defaultConfig.ONE_TRUST_DOMAIN}-test`

const config = {
  MODE,
  COMPLEX_CHECKOUT_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  START_PAGE,
  BASE_API_URL,
  ONE_TRUST_DOMAIN,
}

export default {
  ...defaultConfig,
  ...config,
}
