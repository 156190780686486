import React from 'react'
import PropTypes from 'prop-types'

import Error from '../Error'
import Spinner from '../Spinner'

const Loading = props => {
  let ChildComponent = props.component

  if (props.error) {
    return <Error error={props.error} instructions={props.errorInstructions} />
  } else if (props.loading) {
    return <Spinner position={props.spinnerPosition} />
  }

  return (
    <div>
      {ChildComponent && <ChildComponent {...props} />}
      {!ChildComponent && <div>{props.children}</div>}
    </div>
  )
}

Loading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  spinnerPosition: PropTypes.string,
}

export default Loading
