import { combineReducers } from 'redux'

import AuthReducer from 'containers/Auth/reducer'
import SessionReducer from 'containers/Session/reducer'
import CampaignCodeReducer from 'containers/Pages/Checkout/Cart/CampaignCode/reducer'
import CartReducer from 'containers/Pages/Checkout/Cart/reducer'
import GiftCertificateReducer from 'containers/Pages/Checkout/Cart/GiftCertificate/reducer'
import KlarnaCheckoutReducer from 'containers/Pages/Checkout/KlarnaCheckout/reducer'
import ConfirmationCartReducer from 'containers/Pages/Checkout/ConfirmationPage/ConfirmationCart/reducer'
import GUIReducer from 'containers/GUI/reducer'
import LoginReducer from 'containers/Login/reducer'
import LogoutReducer from 'containers/Login/Logout/reducer'
import PersonalNumberReducer from 'containers/Pages/Checkout/PersonalNumber/reducer'
import NewsletterReducer from 'containers/Pages/Checkout/Newsletter/reducer'
import StudentReducer from 'containers/Pages/Checkout/Student/reducer'
import ResetOrderReducer from 'containers/Pages/Checkout/ResetOrder/reducer'
import WarningStickyReducer from 'containers/WarningSticky/reducer'
import CheckoutPageReducer from 'containers/Pages/Checkout/reducer'
import GiftWrapReducer from 'containers/Pages/Checkout/GiftWrap/reducer'
import GiftWrapOptionsReducer from 'containers/Pages/Checkout/GiftWrapOptions/reducer'

export default combineReducers({
  auth: AuthReducer,
  session: SessionReducer,
  campaignCode: CampaignCodeReducer,
  giftCertificate: GiftCertificateReducer,
  klarnaCheckout: KlarnaCheckoutReducer,
  klarnaSnippet: ConfirmationCartReducer,
  cart: CartReducer,
  gui: GUIReducer,
  login: LoginReducer,
  logout: LogoutReducer,
  personalNumber: PersonalNumberReducer,
  newsletter: NewsletterReducer,
  student: StudentReducer,
  resetOrder: ResetOrderReducer,
  warningSticky: WarningStickyReducer,
  checkoutPage: CheckoutPageReducer,
  giftWrap: GiftWrapReducer,
  giftWrapOptions: GiftWrapOptionsReducer,
})
