export const SHOW_MENU = 'SHOW_MENU'
export const HIDE_MENU_WITH_ANIMATION = 'HIDE_MENU_WITH_ANIMATION'
export const HIDE_MENU_NO_ANIMATION = 'HIDE_MENU_NO_ANIMATION'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_POPOVER = 'SHOW_POPOVER'
export const HIDE_POPOVER = 'HIDE_POPOVER'
export const SHIPPING_AGENTS_MODAL = 'SHIPPING_AGENTS_MODAL'
export const CHANGES_LIST_MODAL = 'CHANGES_LIST_MODAL'
export const CHANGES_MODAL = 'CHANGES_MODAL'
export const ADDRESS_MODAL = 'ADDRESS_MODAL'
export const GIFT_WRAPPING_MODAL = 'GIFT_WRAPPING_MODAL'
export const IDLE_MODAL = 'IDLE_MODAL'
