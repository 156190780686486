import { all, select, takeLatest } from 'redux-saga/effects'

import { navigateTo } from 'utils/location'
import { COMPLEX_CHECKOUT_PATH } from 'components/App/constants'
import { INIT_AUTH_SUCCESS } from 'containers/Auth/constants'
import { LOGIN_SUCCESS } from 'containers/Login/constants'
import { CUSTOMER_TYPES } from '../constants'

function* sagaCompanyRedirect() {
  const state = yield select()
  const { user } = state.auth

  const sendToComplex =
    user &&
    (user.customer_type === CUSTOMER_TYPES.KOMMUN ||
      user.customer_type === CUSTOMER_TYPES.STAT ||
      user.customer_type === CUSTOMER_TYPES.KANDSTING ||
      user.customer_type === CUSTOMER_TYPES.FORETAG)

  if (sendToComplex && window.location.pathname !== COMPLEX_CHECKOUT_PATH) {
    navigateTo(COMPLEX_CHECKOUT_PATH)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest([INIT_AUTH_SUCCESS, LOGIN_SUCCESS], sagaCompanyRedirect),
  ])
}
