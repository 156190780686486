import {
  GET_CART_START,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  REFRESH_SHIPPING_START,
  REFRESH_SHIPPING_SUCCESS,
  REFRESH_SHIPPING_FAILURE,
  UPDATE_CART_ITEM_START,
  SHOW_REMOVE_CART_ITEM_DIALOG,
  HIDE_REMOVE_CART_ITEM_DIALOG,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_CART_ITEM_FAILURE,
  UPDATE_CART_ITEM_WATCHER,
} from './constants'

export const getCartStart = () => ({
  type: GET_CART_START,
})

export const getCartSuccess = cart => ({
  type: GET_CART_SUCCESS,
  cart,
})

export const getCartFailure = error => ({
  type: GET_CART_FAILURE,
  error,
})

export const refreshShippingStart = () => ({
  type: REFRESH_SHIPPING_START,
})

export const refreshShippingSuccess = cart => ({
  type: REFRESH_SHIPPING_SUCCESS,
  cart,
})

export const refreshShippingFailure = error => ({
  type: REFRESH_SHIPPING_FAILURE,
  error,
})

export const updateCartItemWatcher = (productId, quantity) => ({
  type: UPDATE_CART_ITEM_WATCHER,
  productId,
  quantity,
})

export const showRemoveCartItemDialog = (productId, quantity) => {
  return {
    type: SHOW_REMOVE_CART_ITEM_DIALOG,
    productId,
    quantity,
  }
}

export const hideRemoveCartItemDialog = (productId, remove) => ({
  type: HIDE_REMOVE_CART_ITEM_DIALOG,
  productId,
  remove,
})

export const updateCartItemStart = (productId, quantity) => ({
  type: UPDATE_CART_ITEM_START,
  productId,
  quantity,
})

export const updateCartItemSuccess = (productId, cart) => ({
  type: UPDATE_CART_ITEM_SUCCESS,
  productId,
  cart,
})

export const updateCartItemFailure = (productId, error) => ({
  type: UPDATE_CART_ITEM_FAILURE,
  productId,
  error,
})
