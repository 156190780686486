import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  GET_CHECKOUT_SNIPPET_START,
  GET_CHECKOUT_SNIPPET_SUCCESS,
  KCO_SCRIPT_EXECUTION_ERROR,
  GET_CHECKOUT_SNIPPET_FAILURE,
  KCO_SUSPEND,
  KCO_RESUME,
  ADD_SUSPEND_ACTION,
  DELETE_SUSPEND_ACTION,
  UPDATE_SHIPPING_OPTION_SELECTED,
} from './constants'

const initialState = Immutable({
  loading: false,
  error: null,
  suspend: false,
  snippet: null,
  suspendActions: [],
  shippingOptionSelected: null,
})

const Reducer = createReducer(initialState, {
  [GET_CHECKOUT_SNIPPET_START](state) {
    return state.set('loading', true).set('error', null)
  },
  [GET_CHECKOUT_SNIPPET_SUCCESS](state, { cart }) {
    // We must set snippet separately, because every endpoint returning cart won't return a valid snippet.
    return state
      .set('loading', false)
      .set('error', null)
      .set('snippet', cart.html_snippet)
  },
  [GET_CHECKOUT_SNIPPET_FAILURE](state, { error }) {
    return state.set('loading', false).set('error', error)
  },
  [KCO_SCRIPT_EXECUTION_ERROR](state, { error }) {
    return state.set('loading', false).set('error', error)
  },
  [KCO_SUSPEND](state) {
    return state.set('suspend', true)
  },
  [KCO_RESUME](state) {
    return state.set('suspend', false)
  },
  [ADD_SUSPEND_ACTION](state, { baseActionType }) {
    let existingSuspendAction = state.suspendActions.find(
      existingActionType => existingActionType === baseActionType
    )

    if (existingSuspendAction) {
      return state
    } else {
      return state.set(
        'suspendActions',
        state.suspendActions.concat(baseActionType)
      )
    }
  },
  [DELETE_SUSPEND_ACTION](state, { baseActionType }) {
    let existingSuspendAction = state.suspendActions.find(
      existingActionType => existingActionType === baseActionType
    )

    if (existingSuspendAction) {
      return state.set(
        'suspendActions',
        state.suspendActions.filter(
          existingActionType => existingActionType !== baseActionType
        )
      )
    } else {
      return state
    }
  },
  [UPDATE_SHIPPING_OPTION_SELECTED](state, { option }) {
    return state.set('shippingOptionSelected', option)
  },
})

export default Reducer
