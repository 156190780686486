import {
  GET_RESET_ORDER_START,
  GET_RESET_ORDER_SUCCESS,
  GET_RESET_ORDER_FAILURE,
} from './constants'

export const getResetOrderStart = () => ({
  type: GET_RESET_ORDER_START,
})

export const getResetOrderSuccess = cart => {
  return {
    type: GET_RESET_ORDER_SUCCESS,
    cart,
  }
}

export const getResetOrderFailure = error => ({
  type: GET_RESET_ORDER_FAILURE,
  error,
})
