import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import endpoints from 'api/endpoints'
import { request } from 'utils/request'
import { getResetOrderStart } from 'containers/Pages/Checkout/ResetOrder/actions'

import { LOGOUT_START } from './constants'
import { logoutSuccess, logoutFailure } from './actions'

import { hideMenuNoAnimation } from 'containers/GUI/actions'

function* sagaLogoutStart() {
  const state = yield select()

  try {
    yield call(
      request,
      endpoints.createLogoutUrl(state.session.sessionId),
      'GET'
    )

    yield put(logoutSuccess())
    yield put(hideMenuNoAnimation())
    yield put(getResetOrderStart())
  } catch (e) {
    yield put(logoutFailure(e))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(LOGOUT_START, sagaLogoutStart)])
}
