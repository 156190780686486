import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InfoIcon extends Component {
  static propTypes = {
    position: PropTypes.string,
    type: PropTypes.oneOf(['dark', 'error', 'info', 'light']),
  }

  static defaultProps = {
    type: 'light',
  }

  render() {
    const { type } = this.props

    const colors = {
      dark: '#5e5e5e',
      error: '#FF001A',
      info: '#214C62',
      light: '#c2c2c2',
      warning: '#F2BF0A',
    }

    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.83342 13.1667H8.16675V8.16675H9.83342V13.1667ZM9.83342 6.50008H8.16675V4.83341H9.83342V6.50008Z"
          fill={colors[type]}
        />
      </svg>
    )
  }
}
