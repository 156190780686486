import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import {
  ADD_CAMPAIGN_CODE_START,
  ADD_CAMPAIGN_CODE_SUCCESS,
  ADD_CAMPAIGN_CODE_FAILURE,
  DELETE_CAMPAIGN_CODE_START,
  DELETE_CAMPAIGN_CODE_SUCCESS,
  DELETE_CAMPAIGN_CODE_FAILURE,
} from './constants'

const initialState = Immutable({
  campaignCode: null,
  error: null,
  loading: false,
  deleteCampaignCode: {
    error: null,
    loading: false,
  },
})

const Reducer = createReducer(initialState, {
  [ADD_CAMPAIGN_CODE_START](state) {
    return state.set('error', null).set('loading', true)
  },
  [ADD_CAMPAIGN_CODE_SUCCESS](state) {
    return state.set('error', null).set('loading', false)
  },
  [ADD_CAMPAIGN_CODE_FAILURE](state, { error }) {
    return state.set('error', error).set('loading', false)
  },
  [DELETE_CAMPAIGN_CODE_START](state) {
    return state
      .setIn(['deleteCampaignCode', 'error'], null)
      .setIn(['deleteCampaignCode', 'loading'], true)
  },
  [DELETE_CAMPAIGN_CODE_SUCCESS](state) {
    return state
      .setIn(['deleteCampaignCode', 'error'], null)
      .setIn(['deleteCampaignCode', 'loading'], false)
  },
  [DELETE_CAMPAIGN_CODE_FAILURE](state, { error }) {
    return state
      .setIn(['deleteCampaignCode', 'error'], error)
      .setIn(['deleteCampaignCode', 'loading'], false)
  },
})

export default Reducer
