import { convertUnit } from 'utils/money'
import {
  calculateItemsDeliveryTimeSpan,
  calculateTotalPriceBeforeDiscounts,
  createBasketWithItems,
  getCampaignCodeData,
} from './utils'

export const cartEvent = cart => {
  const campaignCodeData = getCampaignCodeData(cart)
  const itemsDeliveryTimeSpan =
    cart.products && cart.products.length > 0
      ? calculateItemsDeliveryTimeSpan(cart.products)
      : null
  const value = cart.freight_ksa
    ? cart.total - cart.freight_ksa.total_freight
    : cart.total
  const valueBeforeDiscounts = cart.products
    ? calculateTotalPriceBeforeDiscounts(cart.products)
    : 0
  const items = createBasketWithItems(cart)

  let result = {
    event: 'view_basket',
    value: convertUnit(value),
    value_before_discounts: convertUnit(valueBeforeDiscounts),
    items: items,
    voyadoCartRef: cart.voyado_cart_ref,
    items_delivery_time_span: itemsDeliveryTimeSpan,
  }

  if (cart.campaign) {
    const couponValue = campaignCodeData
      ? convertUnit(campaignCodeData.discount)
      : null

    result.coupon = cart.campaign
    if (couponValue !== null) result.coupon_value = couponValue
  }

  result.bokus_play_subscriber = cart.play_customer ? 'Y' : 'N'

  return result
}