import Immutable from 'seamless-immutable'
import createReducer from '@ikhsaan/create-reducer'

import { SHOW_WARNING_STICKY, HIDE_WARNING_STICKY } from './constants'

const initialState = Immutable({
  text: '',
  show: false,
})

const Reducer = createReducer(initialState, {
  [SHOW_WARNING_STICKY](state, { text }) {
    return state.set('text', text).set('show', true)
  },
  [HIDE_WARNING_STICKY](state) {
    return state.set('text', '').set('show', false)
  },
})

export default Reducer
