import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import AlertMessage from 'components/AlertMessage'
import { validationMessage } from 'utils/validationMessage'
import { translate } from 'utils/language'

import './style.scss'

export default class Error extends PureComponent {
  static propTypes = {
    error: PropTypes.object.isRequired,
    details: PropTypes.bool,
  }

  static defaultProps = {
    error: null,
    errorInfo: null,
  }

  render() {
    const { error, errorInfo, instructions } = this.props
    const status = error.response ? error.response.status : null
    const code =
      status >= 401 && status < 404
        ? status
        : error.json
        ? error.json.code
        : status
    const errorMessage = code
      ? validationMessage(code)
      : translate('DEFAULT_ERROR')

    return (
      <AlertMessage variant="danger">
        {errorMessage}

        {instructions && (
          <span>
            <br />
            {instructions}
          </span>
        )}
        {errorInfo && (
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        )}
      </AlertMessage>
    )
  }
}
