import { all, delay, put, takeLatest } from 'redux-saga/effects'

import { hideWarningSticky } from './actions'
import { SHOW_WARNING_STICKY } from './constants'

function* sagaShowWarningSticky() {
  yield delay(3000)
  yield put(hideWarningSticky())
}

export default function* rootSaga() {
  yield all([takeLatest(SHOW_WARNING_STICKY, sagaShowWarningSticky)])
}
