import getEnvConfig from 'utils/getEnvConfig'

let BASE_API_URL = getEnvConfig('BASE_API_URL')

export default {
  setBaseApiUrl: baseApiUrl => {
    BASE_API_URL = baseApiUrl
  },
  createAddCampaignCodeUrl: session_id =>
    BASE_API_URL + `/cart/${session_id}/campaign`,
  createDeleteCampaignCodeUrl: (session_id, campaignCodeId) =>
    BASE_API_URL + `/cart/${session_id}/campaign/${campaignCodeId}`,
  createAddGiftCertificateUrl: session_id =>
    BASE_API_URL + `/cart/${session_id}/giftcertificate`,
  createGetCartUrl: session_id => BASE_API_URL + `/cart/${session_id}`,
  createRefreshShippingUrl: session_id =>
    BASE_API_URL + `/cart/${session_id}/refresh_shipping`,
  createUpdateCartItemUrl: (sessionId, productId) =>
    BASE_API_URL + `/cart/${sessionId}/product/${productId}`,
  createResetOrderUrl: (session_id, student) =>
    BASE_API_URL +
    `/cart/${session_id}/reset-order` +
    (student ? `?student=${student}` : ``),
  createGetConfirmationCartUrl: (order_id, session_id) =>
    BASE_API_URL + `/checkout/session/${session_id}/order/${order_id}`,
  createShippingOptionsUrl: session_id =>
    BASE_API_URL + `/shipping/options/${session_id}`,
  createAddPersonalNumberUrl: session_id =>
    BASE_API_URL + `/customer/${session_id}`,
  createShippingAgentUrl: zip_code =>
    BASE_API_URL + `/shipping/agent/${zip_code}`,
  createSetShippingOptionUrl: session_id =>
    BASE_API_URL + `/cart/${session_id}/shipping`,
  createSetStudentUrl: session_id =>
    BASE_API_URL + `/customer/${session_id}/options`,
  createSetNewsletterUrl: session_id =>
    BASE_API_URL + `/customer/${session_id}/options`,
  createLoginUrl: session_id => BASE_API_URL + `/customer/${session_id}/login`,
  createLogoutUrl: session_id =>
    BASE_API_URL + `/customer/${session_id}/logout`,
  createGetCustomerUrl: session_id => BASE_API_URL + `/customer/${session_id}`,
  createDeleteSelectedShippingAgentUrl: session_id =>
    BASE_API_URL + `/cart/${session_id}/shipping/agent`,
  createDeleteGiftCertificateUrl: (session_id, giftCertificateId) =>
    BASE_API_URL + `/cart/${session_id}/giftcertificate/${giftCertificateId}`,
  createSetDeliveryAddressCountryIso: (session_id, countryIso) =>
    BASE_API_URL + `/cart/${session_id}/country/${countryIso}`,
  createGiftWrapUrl: session_id => `${BASE_API_URL}/giftwrap/${session_id}`,
}
