import defaultConfig from './default'

const MODE = 'local'

const COMPLEX_CHECKOUT_PATH = 'https://dev.bokus.com/cgi-bin/cart_send_in.cgi'
const FORGOT_PASSWORD_PAGE_PATH =
  'https://dev.bokus.com/cgi-bin/remind_customer.cgi'
const START_PAGE = 'https://dev.bokus.com/'

const BASE_API_URL = process.env.REACT_APP_API_URL || '/api/v1'

const ONE_TRUST_DOMAIN = `${defaultConfig.ONE_TRUST_DOMAIN}-test`

const GET_SESSION_ID_FROM_COOKIE = false

const config = {
  MODE,
  COMPLEX_CHECKOUT_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  START_PAGE,
  BASE_API_URL,
  ONE_TRUST_DOMAIN,
  GET_SESSION_ID_FROM_COOKIE,
}

export default {
  ...defaultConfig,
  ...config,
}
